
.screen-intro {
	
	padding: 0;
	
	.top-logo {
		display: none;	
		margin: 0 auto;
		width: 11rem;
		height: auto;
	}
	
	.text {
		text-align: center;
		margin: .5rem auto 5rem auto;
		order: 2;
		.downbutton {
			margin-top: 1em;
		}
	}
	
	.graphic {
		
		$numImages: 11;
		$animationTime: 500ms;
		$pauseTime: 2000ms;
		$totalTime: (($animationTime + $pauseTime) * $numImages);
		
		position: relative;
		@include aspect-ratio(158,70);
		width: 100%;
		max-width: 500px;
		margin: 1rem auto 0 auto;
		order: 1;
		
		.standard { display: none; }
		
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: auto;
			opacity: 0;
			animation-name: hero-frames;
			animation-iteration-count: infinite;
			animation-fill-mode: both;
			animation-timing-function: linear;
			animation-duration: $totalTime;
			
			@for $i from 1 through $numImages {
				&:nth-child(#{$i}) {
					animation-delay: (($i - 1) * ($animationTime + $pauseTime));
				}
			}

		}
		
		@keyframes hero-frames {
			0% { opacity: 0; }
			#{($animationTime / $totalTime) * 100}% { opacity: 1; }
			#{(($animationTime + $pauseTime) / $totalTime) * 100}% { opacity: 1; }
			#{((($animationTime * 2) + $pauseTime) / $totalTime) * 100}% { opacity: 0; }
			100% { opacity: 0; }
		}
		
		
	}
	
	.flex {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		min-height: calc(100vh - #{$top-bar-height});
		width: 100%;
	}
	
	h1 {
		animation: fade-in 800ms 600ms $ease-out-quart both;
	}
	
	p, hr, .downbutton {
		animation: fade-in 800ms 800ms $ease-out-quart both;
	}
	
	
	@media (min-width: 400px) {
		
		p {
			font-size: 110%;
			&.bluebold {
				font-size: 100%;
			}
		}
		
	}
	
	@media (min-width: $sidebar-min-width) and (min-height: $sidebar-min-height) {
		@include std-padding-vertical;
		.flex {
			padding-top: 4rem;
			min-height: calc(100vh - 3rem);
		}
		.top-logo {
			display: block;
		}
	}
	
	@media (min-width: 720px) and (min-height: 540px) {
		
		.flex {
			width: 100%;
			min-height: calc(100vh - 13rem);
			@include big-padding-horizontal;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			max-width: 1300px;
			margin: 0 auto;
		}
		
		.text {
			text-align: left;
			order: 1;
			margin: 0;
			h1 {
				margin-top: 0;
			}
			hr {
				margin: 2em 0;
			}
		}
		
		.graphic {
			order: 2;
			margin: 0;
			width: 52%;
			max-width: 550px;
			@include aspect-ratio(540,525);
			.standard { display: block; }
			.landscape { display: none; }
		}
		
		h1 {
			animation: fade-in-right 800ms 600ms $ease-out-quart both;
		}
	
		p, hr, .downbutton {
			animation: fade-in-right 800ms 800ms $ease-out-quart both;
		}
	
	}
	
	@media (min-width: 1100px) and (min-height: 640px) {
		
		h1 { font-size: 330%; }
		p {
			font-size: 130%;
			&.bluebold { font-size: 115%; }
		}
		
	
	}
	
	@media (min-width: 1300px) and (min-height: 750px) {
		
		h1 { font-size: 360%; }
		p {
			font-size: 140%;
			&.bluebold { font-size: 120%; }
		}
		
		.graphic {
			width: 55%;
			max-width: none;
			
		}
		
	
	}
	
	@media (min-width: 1700px) and (min-height: 1000px) {
		.flex {
			max-width: 1400px;
		}
		h1 { font-size: 400%; }
		p {
			font-size: 140%;
			&.bluebold { font-size: 120%; }
		}
	}

}
