@keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fade-in-right {
	0% {
		opacity: 0;
		transform: translateX(-.5rem);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes open-menu-from-top {
	0% {
		top: (0 - $top-menu-height + $top-bar-height);
	}
	100% {
		top: $top-bar-height;
	}
}

@keyframes close-menu-from-top {
	0% {
		top: $top-bar-height;
	}
	100% {
		top: (0 - $top-menu-height + $top-bar-height);
	}
}

@keyframes push-container-down-from-top {
	0% {
		top: 0;
	}
	100% {
		top: $top-menu-height;
	}
}

@keyframes push-container-up-from-top {
	0% {
		top: $top-menu-height;
	}
	100% {
		top: 0;
	}
}

@keyframes open-menu-from-side {
	0% {
		left: (0 - $side-menu-width);
	}
	100% {
		left: 0;
	}
}

@keyframes close-menu-from-side {
	0% {
		left: 0;
	}
	100% {
		left: (0 - $side-menu-width);
	}
}

@keyframes push-container-right-from-side {
	0% {
		left: 0;
	}
	100% {
		left: $side-menu-width;
	}
}

@keyframes push-container-left-from-side {
	0% {
		left: $side-menu-width;
	}
	100% {
		left: 0;
	}
}

@keyframes push-right-with-menu {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX($side-menu-width);
	}
}

@keyframes push-left-with-menu {
	0% {
		transform: translateX($side-menu-width);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes push-down-with-menu {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY($top-menu-height);
	}
}

@keyframes push-up-with-menu {
	0% {
		transform: translateY($top-menu-height);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes illustration-bubble-in {
	0% {
		opacity: 0;
		transform: translateX(-50%) translateY(.25em);
	}
	100% {
		opacity: 1;
		transform: translateX(-50%) translateY(0);
	}
}

@keyframes illustration-bubble-out {
	0% {
		opacity: 1;
		transform: translateX(-50%) translateY(0);
	}
	100% {
		opacity: 0;
		transform: translateX(-50%) translateY(.25em);
	}
}

@keyframes modal-panel-in {
	0% {
		//transform: translateX(-50%) translateY(-45%);
		top: 52%;
	}
	100% {
		//transform: translateX(-50%) translateY(-50%);
		top: 50%;
	}
}

@keyframes modal-panel-out {
	0% {
		//transform: translateX(-50%) translateY(-50%);
		top: 50%;
	}
	100% {
		//transform: translateX(-50%) translateY(-55%);
		top: 48%;
	}
}

@keyframes loader {
	0% {
		transform: translateX(-50%) translateY(-50%) rotateZ(0deg);
	}
	100% {
		transform: translateX(-50%) translateY(-50%) rotateZ(-360deg);
	}
}

/*
	
@keyframes seesaw {
	0% {
		transform: rotateZ(-10deg);
	}
	100% {
		transform: rotateZ(10deg);
	}
}

@keyframes seesaw-alt {
	0% {
		transform: rotateZ(10deg);
	}
	100% {
		transform: rotateZ(-10deg);
	}
}

*/