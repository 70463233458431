.debug-vpwidth {
	position: fixed;
	z-index: 9999;
	opacity: .2;
	bottom: 0;
	right: 0;
	font-size: 10pt;
	background: #000;
	color: #fff;
	padding: .25em .35em;
	transition: opacity 100ms ease-in;
	&:hover {
		opacity: .7;
	}
}

