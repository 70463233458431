.container {
	
	position: relative;
	z-index: 0;
	top: 0;
	left: 0;
	margin: $top-bar-height 0 0 0;
	overflow-x: hidden;
	
	@media (min-width: $sidebar-min-width) and (min-height: $sidebar-min-height) {
		margin: 0 0 0 $side-bar-width;
	}
	
}

.menubar {
	
	position: fixed;
	z-index: 20;
	top: 0;
	left: 0;
	width: 100%;
	min-width: $min-page-width;
	height: $top-bar-height;
	background-color: $col-sidebar-bg;
	@include std-padding-horizontal;
	text-align: right;
	cursor: pointer;
	user-select: none;
	
	.caption {
		display: none;
		position: absolute;
		margin: 0;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%) rotateZ(-90deg);
		@include font-bold;
		font-size: 100%;
		color: $col-light-grey;
		white-space: nowrap;
		&.fadeout {
			animation: fade-out 100ms $ease-in-quad both;
		}
		&.fadein {
			animation: fade-in 100ms $ease-out-quad both;
		}
	}
	
	i {
		display: inline-block;
		@include image-contain;
		width: 2rem;
		height: 2rem;
		margin-top: .75em;
	}
	
	.logo {
		display: none;
		position: absolute;
		left: 50%;
		@include std-bottom;
		transform: translateX(-50%);
		width: 1.15rem;
		height: auto;
	}
	
	.horizontal-logo {
		position: absolute;
		top: 50%;
		@include std-left;
		transform: translateY(-50%);
		width: 12.5rem;
		height: auto;
	}
	
}

.menu {
	
	position: fixed;
	z-index: 15;
	width: 100%;
	min-width: $min-page-width;
	height: $top-menu-height;
	top: (0 - $top-menu-height + $top-bar-height);
	left: 0;
	background-color: $col-menu-bg;
	user-select: none;
	
	ul {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		li {
			margin: 1em 0;
			text-align: center;
			a {
				@include font-bold;
				text-decoration: none;
				color: $col-blue;
				white-space: nowrap;
				transition: color 150ms ease-out;
				&.active {
					color: $col-light-grey;
				}
				&:hover,
				&:focus {
					color: $col-white;
				}
			}
		}
	}
	
	.inline-button {
		margin-top: 1.5em;
		a {
			color: $col-white;
			font-size: 90%;
			transition: background-color 200ms $ease-out-quad;
		}
	}
	
	.inline-copyright,
	.bottom-copyright {
		@include font-bold;
		color: rgba(100,100,100,.8);
	}
	
	.inline-copyright {
		margin-top: 1.5em;
	}
	
	.bottom-copyright {
		display: none;
		position: absolute;
		left: 2.5rem;
		@include std-bottom;
		margin: 0;
	}
	
}

.menu-open {
	
	.menu {
		animation: open-menu-from-top 0ms linear both;
	}
	
	.container {
		animation: push-container-down-from-top 0ms linear both;
	}
	
	.demo-button,
	.sticky-item .sticky {
		animation: push-down-with-menu 0ms linear both;
	}

}

.menu-opening {
	
	.menu {
		animation: open-menu-from-top $menu-animation-time $menu-animation-ease-in both;
	}
	
	.container {
		animation: push-container-down-from-top $menu-animation-time $menu-animation-ease-in both;
	}
	
	.demo-button,
	.sticky-item .sticky {
		animation: push-down-with-menu $menu-animation-time $menu-animation-ease-in both;
	}

}

.menu-closed {
		
	.menu {
		animation: close-menu-from-top $menu-animation-time $menu-animation-ease-out both;
	}
	
	.container {
		animation: push-container-up-from-top $menu-animation-time $menu-animation-ease-out both;
	}	
	
	.demo-button,
	.sticky-item .sticky {
		animation: push-up-with-menu $menu-animation-time $menu-animation-ease-out both;
	}
		
}



// switch to sidebar
@media (min-width: $sidebar-min-width) and (min-height: $sidebar-min-height) {

	.menubar {
		
		width: $side-bar-width;
		min-width: initial;
		height: 100%;
		text-align: center;
		@include std-padding-vertical;
		padding-left: 0;
		padding-right: 0;
		transition: background-color 200ms $ease-out-quad;
		
		.caption {
			display: block;
		}
		
		i {
			margin: 0;
		}
		
		.logo {
			display: block;
		}
		
		.horizontal-logo {
			display: none;
		}
		
		&:hover {
			background-color: mix($col-sidebar-bg, $col-menu-bg);
		}

	}	
	
	
	.menu {
		width: ($side-menu-width + .1rem);
		min-width: initial;
		height: 100%;
		top: 0;
		left: (0 - $side-menu-width);
		ul {
			left: 2.5rem;
			transform: translateY(-50%);	
			li {
				margin: 1.25em 0;
				text-align: left;
				//font-size: 110%;
			}
		}
		
		.inline-copyright,
		.inline-button {	
			display: none;
		}
		
		.bottom-copyright {
			display: block;
		}
		
	}

	.menu-open {
		
		.menu {
			animation: open-menu-from-side 0ms linear both;
		}
		
		.menubar,
		.container,
		#particles {
			animation: push-container-right-from-side 0ms linear both;
		}

		.demo-button,	
		.sticky-item .sticky {
			animation: push-right-with-menu 0ms linear both;
		}
		
	}

	.menu-opening {
		
		.menu {
			animation: open-menu-from-side $menu-animation-time $menu-animation-ease-in both;
		}
		
		.menubar,
		.container,
		#particles {
			animation: push-container-right-from-side $menu-animation-time $menu-animation-ease-in both;
		}

		.demo-button,
		.sticky-item .sticky {
			animation: push-right-with-menu $menu-animation-time $menu-animation-ease-in both;
		}
		
	}
	
	.menu-closed {
		
		.menu {
			animation: close-menu-from-side $menu-animation-time $menu-animation-ease-out both;
		}
		
		.menubar,
		.container,
		#particles {
			animation: push-container-left-from-side $menu-animation-time $menu-animation-ease-out both;
		}
		
		.demo-button,
		.sticky-item .sticky {
			animation: push-left-with-menu $menu-animation-time $menu-animation-ease-out both;
		}
		
	}
	
}