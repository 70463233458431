/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

.cf {
  zoom: 1; }
  .cf::before, .cf::after {
    content: ' ';
    display: table; }
  .cf::after {
    clear: both; }

/*
@mixin font-semibold {
	font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 600;
}
*/
@keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }
@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@keyframes fade-in-right {
  0% {
    opacity: 0;
    transform: translateX(-0.5rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }
@keyframes open-menu-from-top {
  0% {
    top: -21.5rem; }
  100% {
    top: 3.5rem; } }
@keyframes close-menu-from-top {
  0% {
    top: 3.5rem; }
  100% {
    top: -21.5rem; } }
@keyframes push-container-down-from-top {
  0% {
    top: 0; }
  100% {
    top: 25rem; } }
@keyframes push-container-up-from-top {
  0% {
    top: 25rem; }
  100% {
    top: 0; } }
@keyframes open-menu-from-side {
  0% {
    left: -24rem; }
  100% {
    left: 0; } }
@keyframes close-menu-from-side {
  0% {
    left: 0; }
  100% {
    left: -24rem; } }
@keyframes push-container-right-from-side {
  0% {
    left: 0; }
  100% {
    left: 24rem; } }
@keyframes push-container-left-from-side {
  0% {
    left: 24rem; }
  100% {
    left: 0; } }
@keyframes push-right-with-menu {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(24rem); } }
@keyframes push-left-with-menu {
  0% {
    transform: translateX(24rem); }
  100% {
    transform: translateX(0); } }
@keyframes push-down-with-menu {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(25rem); } }
@keyframes push-up-with-menu {
  0% {
    transform: translateY(25rem); }
  100% {
    transform: translateY(0); } }
@keyframes illustration-bubble-in {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(0.25em); }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(0); } }
@keyframes illustration-bubble-out {
  0% {
    opacity: 1;
    transform: translateX(-50%) translateY(0); }
  100% {
    opacity: 0;
    transform: translateX(-50%) translateY(0.25em); } }
@keyframes modal-panel-in {
  0% {
    top: 52%; }
  100% {
    top: 50%; } }
@keyframes modal-panel-out {
  0% {
    top: 50%; }
  100% {
    top: 48%; } }
@keyframes loader {
  0% {
    transform: translateX(-50%) translateY(-50%) rotateZ(0deg); }
  100% {
    transform: translateX(-50%) translateY(-50%) rotateZ(-360deg); } }
/*
	
@keyframes seesaw {
	0% {
		transform: rotateZ(-10deg);
	}
	100% {
		transform: rotateZ(10deg);
	}
}

@keyframes seesaw-alt {
	0% {
		transform: rotateZ(10deg);
	}
	100% {
		transform: rotateZ(-10deg);
	}
}

*/
.img-menu-icon {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzUgMjciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgaWQ9IlBhZ2UtMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBpZD0ibWVudS1pY29uIiBmaWxsPSIjNDM4Q0NCIj48cGF0aCBkPSJNMiA0aDMwLjk5OGMxLjEwNSAwIDItLjg5NSAyLTJzLS44OTUtMi0yLTJIMkMuODk1IDAgMCAuODk1IDAgMnMuODk1IDIgMiAyeiIgaWQ9IlN0cm9rZS0xIi8+PHBhdGggZD0iTTIgMTUuMTg1aDIzLjk5OGMxLjEwNSAwIDItLjg5NiAyLTIgMC0xLjEwNS0uODk1LTItMi0ySDJjLTEuMTA1IDAtMiAuODk1LTIgMiAwIDEuMTA0Ljg5NSAyIDIgMnoiIGlkPSJTdHJva2UtMyIvPjxwYXRoIGQ9Ik0yIDI2LjM3aDI4Ljk5OGMxLjEwNSAwIDItLjg5NiAyLTIgMC0xLjEwNS0uODk1LTItMi0ySDJjLTEuMTA1IDAtMiAuODk1LTIgMiAwIDEuMTA0Ljg5NSAyIDIgMnoiIGlkPSJTdHJva2UtNSIvPjwvZz48L2c+PC9zdmc+); }

.img-right-arrow-dark-blue {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzUgNjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgaWQ9IlBhZ2UtMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBpZD0icmlnaHQtYXJyb3ctZGFyay1ibHVlIiBmaWxsPSIjMzE2REEyIj48cGF0aCBkPSJNMS4wMjggNTQuOTE3Yy0xLjM3IDEuMzktMS4zNyAzLjY0NyAwIDUuMDQgMS4zNyAxLjM5IDMuNTkyIDEuMzkgNC45NjMgMEwzNSAzMC41IDUuOTkgMS4wNDVDNC42Mi0uMzQ4IDIuNC0uMzQ4IDEuMDMgMS4wNDRjLTEuMzcgMS4zOS0xLjM3IDMuNjQ4IDAgNS4wNEwyNS4wNzQgMzAuNSAxLjAyOCA1NC45MTh6IiBpZD0iUGFnZS0xIi8+PC9nPjwvZz48L3N2Zz4=); }

.img-left-arrow-dark-blue {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzUgNjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgaWQ9IlBhZ2UtMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBpZD0ibGVmdC1hcnJvdy1kYXJrLWJsdWUiIGZpbGw9IiMzMTZEQTIiPjxwYXRoIGQ9Ik0zMy45NzIgNTQuOTE3YzEuMzcgMS4zOSAxLjM3IDMuNjQ3IDAgNS4wNC0xLjM3IDEuMzktMy41OTIgMS4zOS00Ljk2MyAwTDAgMzAuNSAyOS4wMSAxLjA0NWMxLjM3LTEuMzkyIDMuNTkyLTEuMzkyIDQuOTYyIDAgMS4zNyAxLjM5IDEuMzcgMy42NDggMCA1LjA0TDkuOTI2IDMwLjVsMjQuMDQ2IDI0LjQxN3oiIGlkPSJQYWdlLTEiLz48L2c+PC9nPjwvc3ZnPg==); }

html {
  min-height: 100%;
  font-size: 10pt; }

body {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #6F6F6E;
  min-width: 320px;
  min-height: 100%;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden; }

.overflow-fix {
  overflow-x: hidden; }

#particles {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; }
  @media (min-width: 650px) and (min-height: 500px) {
    #particles {
      height: 180vh; } }

.screen {
  position: relative;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  min-height: calc(100vh - 3.5rem); }
  @media (min-height: 600px) {
    .screen {
      padding-top: 2rem;
      padding-bottom: 2rem; } }
  @media (min-width: 700px) {
    .screen {
      padding-left: 2rem;
      padding-right: 2rem; } }
  @media (min-width: 900px) {
    .screen {
      padding-left: 2.5rem;
      padding-right: 2.5rem; } }
  @media (min-width: 650px) and (min-height: 500px) {
    .screen {
      min-height: 100vh; } }

.sticky-container .sticky-item {
  user-select: none; }
  .sticky-container .sticky-item .fluid {
    opacity: 1; }
  .sticky-container .sticky-item .sticky {
    opacity: 0;
    display: none;
    position: fixed;
    margin-top: 3.5rem;
    /*
    .menu-open & { display: block; }
    @media (min-width: $sidebar-min-width) and (min-height: $sidebar-min-height) {
    	.menu-open & { display: none; }
    }
    */ }
    @media (min-width: 650px) and (min-height: 500px) {
      .sticky-container .sticky-item .sticky {
        margin-top: 0;
        margin-left: 5rem; } }
.sticky-container.stuck .fluid {
  opacity: 0;
  display: none; }
.sticky-container.stuck .sticky {
  opacity: 1;
  display: block; }

.demo-button-wrapper {
  display: none;
  position: fixed;
  z-index: 9;
  right: 1.5rem;
  bottom: 1.5rem; }
  @media (min-width: 700px) {
    .demo-button-wrapper {
      right: 2rem; } }
  @media (min-width: 900px) {
    .demo-button-wrapper {
      right: 2.5rem; } }
  @media (min-height: 600px) {
    .demo-button-wrapper {
      bottom: 2rem; } }
  @media (min-width: 650px) and (min-height: 500px) {
    .demo-button-wrapper {
      display: block; } }

.line {
  position: absolute;
  z-index: 1;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.15);
  transform-origin: 0 50%; }

.swipe {
  overflow: hidden;
  visibility: hidden;
  position: relative; }

.swipe-wrap {
  overflow: hidden;
  position: relative; }

.swipe-wrap > div {
  float: left;
  width: 100%;
  position: relative; }

.button {
  display: inline-block;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  background-color: #316DA2;
  color: #fff;
  text-decoration: none;
  padding: 1em 1.5em;
  border-radius: .3em;
  transition: background-color 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  cursor: pointer;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.15); }
  .button::after {
    display: inline-block;
    vertical-align: baseline;
    content: '';
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTYgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgaWQ9IlBhZ2UtMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBpZD0id2hpdGUtYXJyb3ctcmlnaHQiIGZpbGw9IiNGRkYiPjxwYXRoIGQ9Ik00LjI0LjZsMTEuNjEzIDExLjYxNWMuMTk2LjE5Ni4xOTYuNTMyIDAgLjczTDQuMjQgMjQuNTU2Yy0uMTk3LjE5Ni0uNTMzLjE5Ni0uNzMgMGwtMy41MzQtMy41MzVjLS4xOTYtLjE5Ni0uMTk2LS41MzIgMC0uNzNMNy42OSAxMi41OC0uMDI0IDQuODY0Yy0uMTk2LS4xOTYtLjE5Ni0uNTMzIDAtLjczTDMuNTEuNjAyYy4xMTMtLjA4My4yMjMtLjE0LjM2My0uMTQuMTQyIDAgLjI1NC4wNTcuMzY2LjE0IiBpZD0iRmlsbC00MSIvPjwvZz48L2c+PC9zdmc+);
    width: .7em;
    height: .85em;
    margin-left: .5em; }
  .button:hover, .button:focus {
    background-color: #4A94D0; }

.downbutton {
  display: inline-block;
  width: 2.75rem;
  height: 2.75rem;
  background-color: #fff;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNjEgMzUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgaWQ9IlBhZ2UtMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBpZD0iZG93bi1hcnJvdy1ibHVlIiBmaWxsPSIjNDM4Q0NCIj48cGF0aCBkPSJNNi4wNSAxLjAzOGMtMS4zODMtMS4zODQtMy42MjctMS4zODQtNS4wMTIgMC0xLjM4NCAxLjM4NC0xLjM4NCAzLjYzIDAgNS4wMTNsMjkuMyAyOS4zMDMgMjkuMy0yOS4zMDJjMS4zODUtMS4zODMgMS4zODUtMy42MjggMC01LjAxMi0xLjM4My0xLjM4NC0zLjYyOC0xLjM4NC01LjAxMiAwbC0yNC4yOSAyNC4yOUw2LjA1MiAxLjAzN3oiIGlkPSJQYWdlLTEiLz48L2c+PC9nPjwvc3ZnPg==);
  background-position: 50% 55%;
  background-repeat: no-repeat;
  background-size: 50% auto;
  transition: box-shadow 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  .downbutton:hover, .downbutton:focus {
    box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.3); }
  @media (min-width: 700px) {
    .downbutton {
      width: 3rem;
      height: 3rem; } }
  @media (min-width: 1200px) {
    .downbutton {
      width: 3.5rem;
      height: 3.5rem; } }

h1, h2, h3 {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #4A94D0;
  margin: .6em 0; }

h1 {
  font-size: 140%; }
  @media (min-width: 400px) {
    h1 {
      font-size: 180%; } }
  @media (min-width: 700px) {
    h1 {
      font-size: 220%; } }
  @media (min-width: 900px) {
    h1 {
      font-size: 280%; } }

p {
  margin: 1em 0;
  line-height: 1.8; }

ul.dot {
  list-style-type: disc;
  line-height: 1.8;
  margin-left: 2em; }
  ul.dot li {
    margin: .3em 0; }

strong {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700; }

p.bluebold {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #4A94D0; }

hr {
  margin: 1.8em auto;
  width: 4em;
  outline: none;
  border: none;
  height: 2px;
  background-color: #4A94D0; }

input[type=text],
input[type=tel],
input[type=email] {
  display: block;
  outline: none;
  appearance: none;
  font-family: inherit;
  font-size: 90%;
  color: #6F6F6E;
  margin: 1.25em 0;
  width: 100%;
  border: solid 1px #ccc;
  border-radius: .25rem;
  padding: .85em 1.4em;
  transition: border-color 150ms ease-out, color 150ms ease-out; }
  input[type=text]::-webkit-input-placeholder,
  input[type=tel]::-webkit-input-placeholder,
  input[type=email]::-webkit-input-placeholder {
    color: #ccc; }
  input[type=text]:-moz-placeholder,
  input[type=tel]:-moz-placeholder,
  input[type=email]:-moz-placeholder {
    color: #ccc; }
  input[type=text]::-moz-placeholder,
  input[type=tel]::-moz-placeholder,
  input[type=email]::-moz-placeholder {
    color: #ccc; }
  input[type=text]:-ms-input-placeholder,
  input[type=tel]:-ms-input-placeholder,
  input[type=email]:-ms-input-placeholder {
    color: #ccc; }
  input[type=text]:hover, input[type=text]:focus,
  input[type=tel]:hover,
  input[type=tel]:focus,
  input[type=email]:hover,
  input[type=email]:focus {
    border-color: #adadad; }
  input[type=text].error,
  input[type=tel].error,
  input[type=email].error {
    color: #D14850;
    border-color: #D14850;
    box-shadow: 0 0 2px #e59a9e; }

.replacecheckbox {
  display: block;
  position: relative;
  font-size: 90%; }
  .replacecheckbox input[type=checkbox] {
    display: none; }
  .replacecheckbox i {
    display: inline-block;
    width: 2em;
    height: 2em;
    vertical-align: -.67em;
    border: solid 1px #ccc;
    border-radius: .25rem;
    transition: border-color 150ms ease-out;
    margin-right: 1em;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 80% auto; }
  .replacecheckbox input[type=checkbox]:checked ~ i {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNzEgNTEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgaWQ9IlBhZ2UtMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBpZD0idGljay1ibHVlIiBmaWxsPSIjNDM4Q0NCIj48cGF0aCBpZD0iQ29tYmluZWQtU2hhcGUiIGQ9Ik0yOC4yODQgMzMuNzRsLTE5LjgtMTkuOEwwIDIyLjQyNyAyOC4yODQgNTAuNzEgNzAuNzEgOC4yODUgNjIuMjI2LS4yeiIvPjwvZz48L2c+PC9zdmc+); }
  .replacecheckbox:hover i {
    border-color: #adadad; }

.concertina {
  width: 100%; }
  .concertina .section {
    zoom: 1;
    border-bottom: solid 1px #6F6F6E;
    padding: 1rem 0; }
    .concertina .section::before, .concertina .section::after {
      content: ' ';
      display: table; }
    .concertina .section::after {
      clear: both; }
    .concertina .section h2, .concertina .section h3 {
      margin: 0; }
    .concertina .section .toggle {
      float: right;
      display: block;
      width: 2rem;
      height: 2rem;
      margin: 0 0 0 1rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzEgMzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxwYXRoIGQ9Ik0zMSAxNmMwIDguMDYtNi45NCAxNS0xNSAxNS05LjA2IDAtMTYtNi45NC0xNi0xNUMwIDYuOTQgNi45NCAwIDE2IDBjOC4wNiAwIDE1IDYuOTQgMTUgMTZ6IiBpZD0icGF0aC0xIi8+PG1hc2sgaWQ9Im1hc2stMiIgeD0iMCIgeT0iMCIgd2lkdGg9IjMxIiBoZWlnaHQ9IjMxIiBmaWxsPSIjZmZmIj48dXNlIHhsaW5rOmhyZWY9IiNwYXRoLTEiLz48L21hc2s+PC9kZWZzPjxnIGlkPSJQYWdlLTEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGcgaWQ9InBsdXMtd2hpdGUiIHN0cm9rZT0iI0ZGRiI+PHVzZSBpZD0iU3Ryb2tlLTEiIG1hc2s9InVybCgjbWFzay0yKSIgc3Ryb2tlLXdpZHRoPSIyIiB4bGluazpocmVmPSIjcGF0aC0xIi8+PHBhdGggZD0iTTkgMTUuNWgxMyIgaWQ9IlN0cm9rZS0zIi8+PHBhdGggZD0iTTE1LjUgMjJWOSIgaWQ9IlN0cm9rZS01Ii8+PC9nPjwvZz48L3N2Zz4=);
      opacity: .33;
      cursor: pointer;
      transition: opacity 150ms ease-out; }
      .concertina .section .toggle:hover {
        opacity: 1; }
    .concertina .section .inner {
      display: none;
      padding: 1.5rem 0;
      clear: both; }
    .concertina .section.open .toggle {
      opacity: 0;
      cursor: default; }
    @media (min-width: 900px) {
      .concertina .section {
        padding: 1.5rem 0; } }
  .concertina.onwhite .section {
    border-bottom-color: #ccc; }
    .concertina.onwhite .section .toggle {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzEgMzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxwYXRoIGQ9Ik0zMSAxNmMwIDguMDYtNi45NCAxNS0xNSAxNS05LjA2IDAtMTYtNi45NC0xNi0xNUMwIDYuOTQgNi45NCAwIDE2IDBjOC4wNiAwIDE1IDYuOTQgMTUgMTZ6IiBpZD0icGF0aC0xIi8+PG1hc2sgaWQ9Im1hc2stMiIgeD0iMCIgeT0iMCIgd2lkdGg9IjMxIiBoZWlnaHQ9IjMxIiBmaWxsPSIjZmZmIj48dXNlIHhsaW5rOmhyZWY9IiNwYXRoLTEiLz48L21hc2s+PC9kZWZzPjxnIGlkPSJQYWdlLTEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGcgaWQ9InBsdXMtYmxhY2siIHN0cm9rZT0iIzAwMCI+PHVzZSBpZD0iU3Ryb2tlLTEiIG1hc2s9InVybCgjbWFzay0yKSIgc3Ryb2tlLXdpZHRoPSIyIiB4bGluazpocmVmPSIjcGF0aC0xIi8+PHBhdGggZD0iTTkgMTUuNWgxMyIgaWQ9IlN0cm9rZS0zIi8+PHBhdGggZD0iTTE1LjUgMjJWOSIgaWQ9IlN0cm9rZS01Ii8+PC9nPjwvZz48L3N2Zz4=);
      opacity: .3; }
      .concertina.onwhite .section .toggle:hover {
        opacity: .8; }
    .concertina.onwhite .section.open .toggle {
      opacity: 0; }

.flex-fix {
  display: flex;
  flex-direction: row; }

.container {
  position: relative;
  z-index: 0;
  top: 0;
  left: 0;
  margin: 3.5rem 0 0 0;
  overflow-x: hidden; }
  @media (min-width: 650px) and (min-height: 500px) {
    .container {
      margin: 0 0 0 5rem; } }

.menubar {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 320px;
  height: 3.5rem;
  background-color: #2C353A;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-align: right;
  cursor: pointer;
  user-select: none; }
  @media (min-width: 700px) {
    .menubar {
      padding-left: 2rem;
      padding-right: 2rem; } }
  @media (min-width: 900px) {
    .menubar {
      padding-left: 2.5rem;
      padding-right: 2.5rem; } }
  .menubar .caption {
    display: none;
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotateZ(-90deg);
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 100%;
    color: #ccc;
    white-space: nowrap; }
    .menubar .caption.fadeout {
      animation: fade-out 100ms cubic-bezier(0.55, 0.085, 0.68, 0.53) both; }
    .menubar .caption.fadein {
      animation: fade-in 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .menubar i {
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 2rem;
    height: 2rem;
    margin-top: .75em; }
  .menubar .logo {
    display: none;
    position: absolute;
    left: 50%;
    bottom: 1.5rem;
    transform: translateX(-50%);
    width: 1.15rem;
    height: auto; }
    @media (min-height: 600px) {
      .menubar .logo {
        bottom: 2rem; } }
  .menubar .horizontal-logo {
    position: absolute;
    top: 50%;
    left: 1.5rem;
    transform: translateY(-50%);
    width: 12.5rem;
    height: auto; }
    @media (min-width: 700px) {
      .menubar .horizontal-logo {
        left: 2rem; } }
    @media (min-width: 900px) {
      .menubar .horizontal-logo {
        left: 2.5rem; } }

.menu {
  position: fixed;
  z-index: 15;
  width: 100%;
  min-width: 320px;
  height: 25rem;
  top: -21.5rem;
  left: 0;
  background-color: #1F272B;
  user-select: none; }
  .menu ul {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .menu ul li {
      margin: 1em 0;
      text-align: center; }
      .menu ul li a {
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: 700;
        text-decoration: none;
        color: #4A94D0;
        white-space: nowrap;
        transition: color 150ms ease-out; }
        .menu ul li a.active {
          color: #ccc; }
        .menu ul li a:hover, .menu ul li a:focus {
          color: #fff; }
  .menu .inline-button {
    margin-top: 1.5em; }
    .menu .inline-button a {
      color: #fff;
      font-size: 90%;
      transition: background-color 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  .menu .inline-copyright,
  .menu .bottom-copyright {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    color: rgba(100, 100, 100, 0.8); }
  .menu .inline-copyright {
    margin-top: 1.5em; }
  .menu .bottom-copyright {
    display: none;
    position: absolute;
    left: 2.5rem;
    bottom: 1.5rem;
    margin: 0; }
    @media (min-height: 600px) {
      .menu .bottom-copyright {
        bottom: 2rem; } }

.menu-open .menu {
  animation: open-menu-from-top 0ms linear both; }
.menu-open .container {
  animation: push-container-down-from-top 0ms linear both; }
.menu-open .demo-button,
.menu-open .sticky-item .sticky {
  animation: push-down-with-menu 0ms linear both; }

.menu-opening .menu {
  animation: open-menu-from-top 650ms cubic-bezier(0.455, 0.03, 0.2, 0.975) both; }
.menu-opening .container {
  animation: push-container-down-from-top 650ms cubic-bezier(0.455, 0.03, 0.2, 0.975) both; }
.menu-opening .demo-button,
.menu-opening .sticky-item .sticky {
  animation: push-down-with-menu 650ms cubic-bezier(0.455, 0.03, 0.2, 0.975) both; }

.menu-closed .menu {
  animation: close-menu-from-top 650ms cubic-bezier(0.645, 0.045, 0.355, 1) both; }
.menu-closed .container {
  animation: push-container-up-from-top 650ms cubic-bezier(0.645, 0.045, 0.355, 1) both; }
.menu-closed .demo-button,
.menu-closed .sticky-item .sticky {
  animation: push-up-with-menu 650ms cubic-bezier(0.645, 0.045, 0.355, 1) both; }

@media (min-width: 650px) and (min-height: 500px) {
  .menubar {
    width: 5rem;
    min-width: initial;
    height: 100%;
    text-align: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 0;
    padding-right: 0;
    transition: background-color 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94); } }
  @media (min-width: 650px) and (min-height: 500px) and (min-height: 600px) {
    .menubar {
      padding-top: 2rem;
      padding-bottom: 2rem; } }
@media (min-width: 650px) and (min-height: 500px) {
    .menubar .caption {
      display: block; }
    .menubar i {
      margin: 0; }
    .menubar .logo {
      display: block; }
    .menubar .horizontal-logo {
      display: none; }
    .menubar:hover {
      background-color: #262e33; }

  .menu {
    width: 24.1rem;
    min-width: initial;
    height: 100%;
    top: 0;
    left: -24rem; }
    .menu ul {
      left: 2.5rem;
      transform: translateY(-50%); }
      .menu ul li {
        margin: 1.25em 0;
        text-align: left; }
    .menu .inline-copyright,
    .menu .inline-button {
      display: none; }
    .menu .bottom-copyright {
      display: block; }

  .menu-open .menu {
    animation: open-menu-from-side 0ms linear both; }
  .menu-open .menubar,
  .menu-open .container,
  .menu-open #particles {
    animation: push-container-right-from-side 0ms linear both; }
  .menu-open .demo-button,
  .menu-open .sticky-item .sticky {
    animation: push-right-with-menu 0ms linear both; }

  .menu-opening .menu {
    animation: open-menu-from-side 650ms cubic-bezier(0.455, 0.03, 0.2, 0.975) both; }
  .menu-opening .menubar,
  .menu-opening .container,
  .menu-opening #particles {
    animation: push-container-right-from-side 650ms cubic-bezier(0.455, 0.03, 0.2, 0.975) both; }
  .menu-opening .demo-button,
  .menu-opening .sticky-item .sticky {
    animation: push-right-with-menu 650ms cubic-bezier(0.455, 0.03, 0.2, 0.975) both; }

  .menu-closed .menu {
    animation: close-menu-from-side 650ms cubic-bezier(0.645, 0.045, 0.355, 1) both; }
  .menu-closed .menubar,
  .menu-closed .container,
  .menu-closed #particles {
    animation: push-container-left-from-side 650ms cubic-bezier(0.645, 0.045, 0.355, 1) both; }
  .menu-closed .demo-button,
  .menu-closed .sticky-item .sticky {
    animation: push-left-with-menu 650ms cubic-bezier(0.645, 0.045, 0.355, 1) both; } }
.modal {
  display: none;
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; }
  .modal .panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 1.5rem;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch; }
  .modal .close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    width: 1rem;
    height: 1rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNDcgNDciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgaWQ9IlBhZ2UtMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBpZD0iY3Jvc3MtYmxhY2siIGZpbGw9IiMwMDAiPjxwYXRoIGlkPSJDb21iaW5lZC1TaGFwZSIgZD0iTTE5IDI0TDAgNDNsNCA0IDE5LTE5IDIwIDE5IDQtNC0xOS0xOUw0NyA0bC00LTQtMjAgMTlMNCAwIDAgNHoiLz48L2c+PC9nPjwvc3ZnPg==);
    opacity: .2;
    transition: opacity 150ms ease-out; }
    .modal .close:hover {
      opacity: .5; }
  .modal .logo {
    display: block;
    width: 1.1em;
    height: auto;
    margin: 0 auto 1.67rem auto; }
  .modal .loader {
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    width: 2.2rem;
    height: 2.2rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBpZD0iUGFnZS0xIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxnIGlkPSJsb2FkZXIiIGZpbGw9IiM0MzhDQ0IiPjxwYXRoIGQ9Ik0xMDAgNTBjMCAyNy42MTQtMjIuMzg2IDUwLTUwIDUwUzAgNzcuNjE0IDAgNTAgMjIuMzg2IDAgNTAgMHY4QzI2LjgwNCA4IDggMjYuODA0IDggNTBzMTguODA0IDQyIDQyIDQyIDQyLTE4LjgwNCA0Mi00Mmg4eiIgaWQ9IkNvbWJpbmVkLVNoYXBlIi8+PC9nPjwvZz48L3N2Zz4=);
    animation: loader 1s linear infinite;
    opacity: 0; }
  .modal p {
    text-align: center;
    max-width: 26em;
    margin-left: auto;
    margin-right: auto; }
  .modal form {
    margin-top: 1.33rem;
    position: relative;
    z-index: 1; }
  .modal .replacecheckbox {
    margin-top: 1.2rem; }
  .modal .submit {
    text-align: center;
    display: block;
    width: 100%;
    margin: .4em 0; }
  .modal .thanks {
    position: absolute;
    z-index: 0;
    top: 2.5rem;
    left: 0;
    padding: 1.5rem;
    width: 100%;
    text-align: center;
    opacity: 0; }
    .modal .thanks h3 {
      font-size: 150%; }
    .modal .thanks .button {
      width: 100%;
      margin-top: 1rem; }
  .modal .loader,
  .modal .intro,
  .modal .thanks,
  .modal form {
    transition: opacity 330ms ease-out; }
  .modal .loading .loader {
    opacity: 1; }
  .modal .loading .intro,
  .modal .loading form {
    opacity: 0; }
  .modal .success .thanks {
    opacity: 1;
    z-index: 2; }
  .modal .success .intro,
  .modal .success form {
    opacity: 0; }
  .modal.appear {
    display: block;
    animation: fade-in 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .modal.hide {
    display: block;
    animation: fade-out 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  @media (min-width: 400px) and (min-height: 500px) {
    .modal {
      background: rgba(0, 0, 0, 0.85); }
      .modal .panel {
        top: 50%;
        left: 50%;
        width: 90%;
        height: auto;
        max-width: 24em;
        transform: translateX(-50%) translateY(-50%);
        border-radius: .25rem;
        box-shadow: 0 0 1em rgba(0, 0, 0, 0.66);
        padding: 1.5rem 2rem;
        overflow: auto; }
      .modal .thanks {
        padding: 1.5rem 2rem; }
      .modal.appear .panel {
        animation: modal-panel-in 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
      .modal.hide .panel {
        animation: modal-panel-out 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.screen-intro {
  padding: 0; }
  .screen-intro .top-logo {
    display: none;
    margin: 0 auto;
    width: 11rem;
    height: auto; }
  .screen-intro .text {
    text-align: center;
    margin: .5rem auto 5rem auto;
    order: 2; }
    .screen-intro .text .downbutton {
      margin-top: 1em; }
  .screen-intro .graphic {
    position: relative;
    width: 100%;
    max-width: 500px;
    margin: 1rem auto 0 auto;
    order: 1; }
    .screen-intro .graphic:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 44.3038%; }
    .screen-intro .graphic .standard {
      display: none; }
    .screen-intro .graphic img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      opacity: 0;
      animation-name: hero-frames;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      animation-timing-function: linear;
      animation-duration: 27500ms; }
      .screen-intro .graphic img:nth-child(1) {
        animation-delay: 0ms; }
      .screen-intro .graphic img:nth-child(2) {
        animation-delay: 2500ms; }
      .screen-intro .graphic img:nth-child(3) {
        animation-delay: 5000ms; }
      .screen-intro .graphic img:nth-child(4) {
        animation-delay: 7500ms; }
      .screen-intro .graphic img:nth-child(5) {
        animation-delay: 10000ms; }
      .screen-intro .graphic img:nth-child(6) {
        animation-delay: 12500ms; }
      .screen-intro .graphic img:nth-child(7) {
        animation-delay: 15000ms; }
      .screen-intro .graphic img:nth-child(8) {
        animation-delay: 17500ms; }
      .screen-intro .graphic img:nth-child(9) {
        animation-delay: 20000ms; }
      .screen-intro .graphic img:nth-child(10) {
        animation-delay: 22500ms; }
      .screen-intro .graphic img:nth-child(11) {
        animation-delay: 25000ms; }
@keyframes hero-frames {
  0% {
    opacity: 0; }
  1.81818% {
    opacity: 1; }
  9.09091% {
    opacity: 1; }
  10.90909% {
    opacity: 0; }
  100% {
    opacity: 0; } }
  .screen-intro .flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: calc(100vh - 3.5rem);
    width: 100%; }
  .screen-intro h1 {
    animation: fade-in 800ms 600ms cubic-bezier(0.165, 0.84, 0.44, 1) both; }
  .screen-intro p, .screen-intro hr, .screen-intro .downbutton {
    animation: fade-in 800ms 800ms cubic-bezier(0.165, 0.84, 0.44, 1) both; }
  @media (min-width: 400px) {
    .screen-intro p {
      font-size: 110%; }
      .screen-intro p.bluebold {
        font-size: 100%; } }
  @media (min-width: 650px) and (min-height: 500px) {
    .screen-intro {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem; } }
  @media (min-width: 650px) and (min-height: 500px) and (min-height: 600px) {
    .screen-intro {
      padding-top: 2rem;
      padding-bottom: 2rem; } }
  @media (min-width: 650px) and (min-height: 500px) {
      .screen-intro .flex {
        padding-top: 4rem;
        min-height: calc(100vh - 3rem); }
      .screen-intro .top-logo {
        display: block; } }
  @media (min-width: 720px) and (min-height: 540px) {
    .screen-intro .flex {
      width: 100%;
      min-height: calc(100vh - 13rem);
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      max-width: 1300px;
      margin: 0 auto; } }
  @media (min-width: 720px) and (min-height: 540px) and (min-width: 700px) {
    .screen-intro .flex {
      padding-left: 4rem;
      padding-right: 4rem; } }
  @media (min-width: 720px) and (min-height: 540px) and (min-width: 900px) {
    .screen-intro .flex {
      padding-left: 5.5rem;
      padding-right: 5.5rem; } }
  @media (min-width: 720px) and (min-height: 540px) and (min-width: 1100px) {
    .screen-intro .flex {
      padding-left: 7.5rem;
      padding-right: 7.5rem; } }
  @media (min-width: 720px) and (min-height: 540px) {
    .screen-intro .text {
      text-align: left;
      order: 1;
      margin: 0; }
      .screen-intro .text h1 {
        margin-top: 0; }
      .screen-intro .text hr {
        margin: 2em 0; }
    .screen-intro .graphic {
      order: 2;
      margin: 0;
      width: 52%;
      max-width: 550px; }
      .screen-intro .graphic:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 97.22222%; }
      .screen-intro .graphic .standard {
        display: block; }
      .screen-intro .graphic .landscape {
        display: none; }
    .screen-intro h1 {
      animation: fade-in-right 800ms 600ms cubic-bezier(0.165, 0.84, 0.44, 1) both; }
    .screen-intro p, .screen-intro hr, .screen-intro .downbutton {
      animation: fade-in-right 800ms 800ms cubic-bezier(0.165, 0.84, 0.44, 1) both; } }
  @media (min-width: 1100px) and (min-height: 640px) {
    .screen-intro h1 {
      font-size: 330%; }
    .screen-intro p {
      font-size: 130%; }
      .screen-intro p.bluebold {
        font-size: 115%; } }
  @media (min-width: 1300px) and (min-height: 750px) {
    .screen-intro h1 {
      font-size: 360%; }
    .screen-intro p {
      font-size: 140%; }
      .screen-intro p.bluebold {
        font-size: 120%; }
    .screen-intro .graphic {
      width: 55%;
      max-width: none; } }
  @media (min-width: 1700px) and (min-height: 1000px) {
    .screen-intro .flex {
      max-width: 1400px; }
    .screen-intro h1 {
      font-size: 400%; }
    .screen-intro p {
      font-size: 140%; }
      .screen-intro p.bluebold {
        font-size: 120%; } }

.screen-explained {
  background-color: #394449;
  position: relative;
  padding-top: 0;
  padding-bottom: 0; }
  .screen-explained .flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: calc(100vh - 3.5rem);
    margin: 0 auto;
    width: 100%;
    max-width: 1300px; }
    @media (min-width: 650px) and (min-height: 500px) {
      .screen-explained .flex {
        min-height: 100vh; } }
  .screen-explained .concertina {
    padding: 3.5rem 0 8rem 0; }
  .screen-explained h2 {
    font-size: 140%; }
  .screen-explained h3 {
    font-size: 120%; }
  .screen-explained p {
    color: #fff; }
    .screen-explained p.big {
      font-size: 110%; }
  .screen-explained .downbutton {
    position: absolute;
    left: 50%;
    bottom: 1.5rem;
    transform: translateX(-50%); }
    @media (min-height: 600px) {
      .screen-explained .downbutton {
        bottom: 2rem; } }
  @media (min-width: 400px) {
    .screen-explained h2 {
      font-size: 180%; }
    .screen-explained h3 {
      font-size: 130%; } }
  @media (min-width: 700px) {
    .screen-explained h2 {
      font-size: 220%; }
    .screen-explained h3 {
      font-size: 140%; }
    .screen-explained p.big {
      font-size: 130%; } }
  @media (min-width: 720px) {
    .screen-explained {
      padding-left: 0;
      padding-right: 0; }
      .screen-explained .flex {
        padding-left: 2.5rem;
        padding-right: 2.5rem; } }
    @media (min-width: 720px) and (min-width: 700px) {
      .screen-explained .flex {
        padding-left: 4rem;
        padding-right: 4rem; } }
    @media (min-width: 720px) and (min-width: 900px) {
      .screen-explained .flex {
        padding-left: 5.5rem;
        padding-right: 5.5rem; } }
    @media (min-width: 720px) and (min-width: 1100px) {
      .screen-explained .flex {
        padding-left: 7.5rem;
        padding-right: 7.5rem; } }
  @media (min-width: 720px) {
      .screen-explained .left {
        float: left;
        width: 47%; }
      .screen-explained .right {
        float: right;
        width: 47%; } }
  @media (min-width: 900px) {
    .screen-explained p {
      font-size: 110%; }
    .screen-explained .left,
    .screen-explained .right {
      width: 48%; } }
  @media (min-width: 1300px) {
    .screen-explained .left,
    .screen-explained .right {
      width: 48.5%; } }
  @media (min-width: 1700px) and (min-height: 1000px) {
    .screen-explained .flex {
      max-width: 1400px; }
    .screen-explained h2 {
      font-size: 250%; }
    .screen-explained h3 {
      font-size: 150%; }
    .screen-explained p {
      font-size: 120%; }
    .screen-explained p.big {
      font-size: 140%; } }

.reasons-sticky-container {
  position: relative; }
  .reasons-sticky-container .sticky-progress {
    display: none; }
    @media (min-width: 720px) and (min-height: 540px) {
      .reasons-sticky-container .sticky-progress {
        display: block; } }
    .reasons-sticky-container .sticky-progress .fluid,
    .reasons-sticky-container .sticky-progress .sticky {
      left: 2.5rem;
      top: 0;
      bottom: 0; }
      @media (min-width: 700px) {
        .reasons-sticky-container .sticky-progress .fluid,
        .reasons-sticky-container .sticky-progress .sticky {
          left: 4rem; } }
      @media (min-width: 900px) {
        .reasons-sticky-container .sticky-progress .fluid,
        .reasons-sticky-container .sticky-progress .sticky {
          left: 5.5rem; } }
      @media (min-width: 1100px) {
        .reasons-sticky-container .sticky-progress .fluid,
        .reasons-sticky-container .sticky-progress .sticky {
          left: 7.5rem; } }
    .reasons-sticky-container .sticky-progress .fluid {
      position: absolute; }
    .reasons-sticky-container .sticky-progress .sticky {
      z-index: 8; }
    .reasons-sticky-container .sticky-progress .wrapper {
      position: absolute;
      top: 50vh;
      left: 0;
      transform: translateY(-50%); }
    .reasons-sticky-container .sticky-progress a {
      display: block;
      width: .75em;
      height: .75em;
      margin-bottom: 1em;
      border-radius: 50%;
      background-color: #ADCDE8;
      transition: background-color 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94); }
      .reasons-sticky-container .sticky-progress a:last-child {
        margin-bottom: 0; }
      .reasons-sticky-container .sticky-progress a.active {
        background-color: #4A94D0; }
      .reasons-sticky-container .sticky-progress a:nth-child(1):hover {
        background-color: #EA5C1B; }
      .reasons-sticky-container .sticky-progress a:nth-child(2):hover {
        background-color: #48B9B7; }
      .reasons-sticky-container .sticky-progress a:nth-child(3):hover {
        background-color: #905BA1; }
      .reasons-sticky-container .sticky-progress a:nth-child(4):hover {
        background-color: #CC367B; }
      .reasons-sticky-container .sticky-progress a:nth-child(5):hover {
        background-color: #A1C52F; }
      .reasons-sticky-container .sticky-progress a:nth-child(6):hover {
        background-color: #F8AC13; }
      .reasons-sticky-container .sticky-progress a:nth-child(7):hover {
        background-color: #5B9312; }
      .reasons-sticky-container .sticky-progress a:nth-child(8):hover {
        background-color: #F19BC2; }
  .reasons-sticky-container.bottom .sticky-progress .wrapper {
    top: auto;
    bottom: 50vh;
    transform: translateY(50%); }

.screen-reasons .line-start,
.screen-reasons .line-end {
  position: absolute;
  width: 1px;
  height: 1px; }
.screen-reasons .line-start {
  top: 0;
  left: 35%; }
.screen-reasons .line-end {
  bottom: 0;
  left: 70%; }
.screen-reasons .introtitle {
  position: absolute;
  z-index: 2;
  font-size: 90%;
  top: 50%;
  left: 50%;
  width: 14em;
  height: 14em;
  padding: 4.75em 1.5em 0 1.5em;
  transform: translateX(-50%) translateY(-50%);
  background-color: #4A94D0;
  border-radius: 50%;
  text-align: center;
  box-shadow: 0 1em 2.5em rgba(0, 0, 0, 0.15); }
  .screen-reasons .introtitle h2 {
    font-size: inherit;
    color: #fff; }
  .screen-reasons .introtitle .downbutton {
    font-size: inherit;
    width: 2.2em;
    height: 2.2em;
    margin-top: .5em;
    box-shadow: none; }
  @media (min-height: 375px) {
    .screen-reasons .introtitle {
      font-size: 100%; } }
  @media (min-width: 600px) and (min-height: 500px) {
    .screen-reasons .introtitle {
      font-size: 125%; } }
  @media (min-width: 800px) and (min-height: 600px) {
    .screen-reasons .introtitle {
      font-size: 180%; } }
  @media (min-width: 1200px) and (min-height: 900px) {
    .screen-reasons .introtitle {
      font-size: 220%; } }
  @media (min-width: 1700px) and (min-height: 1000px) {
    .screen-reasons .introtitle {
      font-size: 260%; } }
.screen-reasons .positioner {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  transform: translateY(-50%) translateX(-50%); }
  @media (min-width: 700px) {
    .screen-reasons .positioner {
      padding-left: 2rem;
      padding-right: 2rem; } }
  @media (min-width: 900px) {
    .screen-reasons .positioner {
      padding-left: 2.5rem;
      padding-right: 2.5rem; } }
  .screen-reasons .positioner .blob {
    position: absolute;
    top: -6em;
    bottom: 100%;
    width: 5.5em;
    height: 5.5em; }
    .screen-reasons .positioner .blob i {
      position: absolute;
      z-index: 1;
      border-radius: 50%; }
      .screen-reasons .positioner .blob i:nth-child(1) {
        z-index: 2;
        width: 100%;
        height: 100%;
        box-shadow: 0 0.33em 0.75em rgba(0, 0, 0, 0.15);
        background-position: 50% 50%;
        background-size: 90% auto;
        background-repeat: no-repeat; }
      .screen-reasons .positioner .blob i:nth-child(2), .screen-reasons .positioner .blob i:nth-child(3), .screen-reasons .positioner .blob i:nth-child(4) {
        box-shadow: 0 0.15em 0.4em rgba(0, 0, 0, 0.25);
        top: 50%;
        left: 50%;
        transition: all 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
        transform: translateX(-50%) translateY(-50%) scale(0); }
      .screen-reasons .positioner .blob i:nth-child(2) {
        width: .8em;
        height: .8em; }
      .screen-reasons .positioner .blob i:nth-child(3) {
        width: .6em;
        height: .6em; }
      .screen-reasons .positioner .blob i:nth-child(4) {
        width: .6em;
        height: .6em; }
    .screen-reasons .positioner .blob.trigger i:nth-child(2), .screen-reasons .positioner .blob.trigger i:nth-child(3), .screen-reasons .positioner .blob.trigger i:nth-child(4) {
      transform: translateX(-50%) translateY(-50%) scale(1); }
  .screen-reasons .positioner h2 {
    font-size: 110%;
    margin-bottom: .67em; }
    .screen-reasons .positioner h2::after {
      content: '';
      display: block;
      height: 2px;
      width: 2em;
      background-color: #4A94D0;
      margin-top: .67em; }
    .screen-reasons .positioner h2 span {
      display: block;
      font-size: 90%;
      margin-bottom: .2em; }
  .screen-reasons .positioner p {
    font-size: 80%; }
    .screen-reasons .positioner p span {
      display: block;
      font-size: 120%;
      font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 700; }
  .screen-reasons .positioner .downbutton {
    display: none;
    margin-top: .8em; }
.screen-reasons.one h2 span {
  color: #EA5C1B; }
.screen-reasons.one h2::after {
  background-color: #EA5C1B; }
.screen-reasons.one .blob i {
  background-color: #EA5C1B; }
  .screen-reasons.one .blob i:nth-child(1) {
    background-image: url("../img/node-1.svg"); }
  .screen-reasons.one .blob i:nth-child(2) {
    transition-delay: 350ms;
    transition-duration: 380ms; }
  .screen-reasons.one .blob i:nth-child(3) {
    transition-delay: 693ms;
    transition-duration: 1382ms; }
  .screen-reasons.one .blob i:nth-child(4) {
    transition-delay: 728ms;
    transition-duration: 429ms; }
.screen-reasons.two h2 span {
  color: #48B9B7; }
.screen-reasons.two h2::after {
  background-color: #48B9B7; }
.screen-reasons.two .blob i {
  background-color: #48B9B7; }
  .screen-reasons.two .blob i:nth-child(1) {
    background-image: url("../img/node-2.svg"); }
  .screen-reasons.two .blob i:nth-child(2) {
    transition-delay: 324ms;
    transition-duration: 1100ms; }
  .screen-reasons.two .blob i:nth-child(3) {
    transition-delay: 354ms;
    transition-duration: 572ms; }
  .screen-reasons.two .blob i:nth-child(4) {
    transition-delay: 882ms;
    transition-duration: 1471ms; }
.screen-reasons.three h2 span {
  color: #905BA1; }
.screen-reasons.three h2::after {
  background-color: #905BA1; }
.screen-reasons.three .blob i {
  background-color: #905BA1; }
  .screen-reasons.three .blob i:nth-child(1) {
    background-image: url("../img/node-3.svg"); }
  .screen-reasons.three .blob i:nth-child(2) {
    transition-delay: 168ms;
    transition-duration: 1179ms; }
  .screen-reasons.three .blob i:nth-child(3) {
    transition-delay: 491ms;
    transition-duration: 1096ms; }
  .screen-reasons.three .blob i:nth-child(4) {
    transition-delay: 900ms;
    transition-duration: 426ms; }
.screen-reasons.four h2 span {
  color: #CC367B; }
.screen-reasons.four h2::after {
  background-color: #CC367B; }
.screen-reasons.four .blob i {
  background-color: #CC367B; }
  .screen-reasons.four .blob i:nth-child(1) {
    background-image: url("../img/node-4.svg"); }
  .screen-reasons.four .blob i:nth-child(2) {
    transition-delay: 471ms;
    transition-duration: 1138ms; }
  .screen-reasons.four .blob i:nth-child(3) {
    transition-delay: 408ms;
    transition-duration: 1410ms; }
  .screen-reasons.four .blob i:nth-child(4) {
    transition-delay: 735ms;
    transition-duration: 924ms; }
.screen-reasons.five h2 span {
  color: #A1C52F; }
.screen-reasons.five h2::after {
  background-color: #A1C52F; }
.screen-reasons.five .blob i {
  background-color: #A1C52F; }
  .screen-reasons.five .blob i:nth-child(1) {
    background-image: url("../img/node-5.svg"); }
  .screen-reasons.five .blob i:nth-child(2) {
    transition-delay: 151ms;
    transition-duration: 933ms; }
  .screen-reasons.five .blob i:nth-child(3) {
    transition-delay: 145ms;
    transition-duration: 1350ms; }
  .screen-reasons.five .blob i:nth-child(4) {
    transition-delay: 636ms;
    transition-duration: 1081ms; }
.screen-reasons.six h2 span {
  color: #F8AC13; }
.screen-reasons.six h2::after {
  background-color: #F8AC13; }
.screen-reasons.six .blob i {
  background-color: #F8AC13; }
  .screen-reasons.six .blob i:nth-child(1) {
    background-image: url("../img/node-6.svg"); }
  .screen-reasons.six .blob i:nth-child(2) {
    transition-delay: 17ms;
    transition-duration: 1693ms; }
  .screen-reasons.six .blob i:nth-child(3) {
    transition-delay: 107ms;
    transition-duration: 1047ms; }
  .screen-reasons.six .blob i:nth-child(4) {
    transition-delay: 720ms;
    transition-duration: 796ms; }
.screen-reasons.seven h2 span {
  color: #5B9312; }
.screen-reasons.seven h2::after {
  background-color: #5B9312; }
.screen-reasons.seven .blob i {
  background-color: #5B9312; }
  .screen-reasons.seven .blob i:nth-child(1) {
    background-image: url("../img/node-7.svg"); }
  .screen-reasons.seven .blob i:nth-child(2) {
    transition-delay: 23ms;
    transition-duration: 1078ms; }
  .screen-reasons.seven .blob i:nth-child(3) {
    transition-delay: 116ms;
    transition-duration: 1045ms; }
  .screen-reasons.seven .blob i:nth-child(4) {
    transition-delay: 526ms;
    transition-duration: 1629ms; }
.screen-reasons.eight h2 span {
  color: #F19BC2; }
.screen-reasons.eight h2::after {
  background-color: #F19BC2; }
.screen-reasons.eight .blob i {
  background-color: #F19BC2; }
  .screen-reasons.eight .blob i:nth-child(1) {
    background-image: url("../img/node-8.svg"); }
  .screen-reasons.eight .blob i:nth-child(2) {
    transition-delay: 556ms;
    transition-duration: 1402ms; }
  .screen-reasons.eight .blob i:nth-child(3) {
    transition-delay: 840ms;
    transition-duration: 610ms; }
  .screen-reasons.eight .blob i:nth-child(4) {
    transition-delay: 389ms;
    transition-duration: 1183ms; }
.screen-reasons.one .blob {
  right: 7%; }
  .screen-reasons.one .blob.trigger i:nth-child(2) {
    top: 20%;
    left: 140%; }
  .screen-reasons.one .blob.trigger i:nth-child(3) {
    top: 80%;
    left: 115%; }
  .screen-reasons.one .blob.trigger i:nth-child(4) {
    top: 10%;
    left: -40%; }
.screen-reasons.two .blob {
  left: 20%; }
  .screen-reasons.two .blob.trigger i:nth-child(2) {
    top: 100%;
    left: 109%; }
  .screen-reasons.two .blob.trigger i:nth-child(3) {
    top: 130%;
    left: 42%; }
  .screen-reasons.two .blob.trigger i:nth-child(4) {
    top: -10%;
    left: -50%; }
.screen-reasons.three .blob {
  right: 25%; }
  .screen-reasons.three .blob.trigger i:nth-child(2) {
    top: -5%;
    left: 115%; }
  .screen-reasons.three .blob.trigger i:nth-child(3) {
    top: 76%;
    left: 120%; }
  .screen-reasons.three .blob.trigger i:nth-child(4) {
    top: 100%;
    left: -55%; }
.screen-reasons.four .blob {
  left: 10%; }
  .screen-reasons.four .blob.trigger i:nth-child(2) {
    top: -5%;
    left: 160%; }
  .screen-reasons.four .blob.trigger i:nth-child(3) {
    top: 0%;
    left: -40%; }
  .screen-reasons.four .blob.trigger i:nth-child(4) {
    top: 60%;
    left: 130%; }
.screen-reasons.five .blob {
  right: 8%; }
  .screen-reasons.five .blob.trigger i:nth-child(2) {
    top: 80%;
    left: 180%; }
  .screen-reasons.five .blob.trigger i:nth-child(3) {
    top: 140%;
    left: 90%; }
  .screen-reasons.five .blob.trigger i:nth-child(4) {
    top: 5%;
    left: -34%; }
.screen-reasons.six .blob {
  left: 15%; }
  .screen-reasons.six .blob.trigger i:nth-child(3) {
    top: -11%;
    left: -66%; }
  .screen-reasons.six .blob.trigger i:nth-child(3) {
    top: -60%;
    left: -31%; }
  .screen-reasons.six .blob.trigger i:nth-child(4) {
    top: 55%;
    left: 122%; }
.screen-reasons.seven .blob {
  right: 18%; }
  .screen-reasons.seven .blob.trigger i:nth-child(2) {
    top: -11%;
    left: 138%; }
  .screen-reasons.seven .blob.trigger i:nth-child(3) {
    top: 50%;
    left: 150%; }
  .screen-reasons.seven .blob.trigger i:nth-child(4) {
    top: 87%;
    left: -27%; }
.screen-reasons.eight .blob {
  left: 30%; }
  .screen-reasons.eight .blob.trigger i:nth-child(2) {
    top: 5%;
    left: -40%; }
  .screen-reasons.eight .blob.trigger i:nth-child(3) {
    top: -32%;
    left: 20%; }
  .screen-reasons.eight .blob.trigger i:nth-child(4) {
    top: 120%;
    left: 120%; }
.screen-reasons.nine .blob {
  right: 15%; }
  .screen-reasons.nine .blob.trigger i:nth-child(2) {
    top: 52%;
    left: -45%; }
  .screen-reasons.nine .blob.trigger i:nth-child(3) {
    top: 3%;
    left: -19%; }
  .screen-reasons.nine .blob.trigger i:nth-child(4) {
    top: 49%;
    left: 140%; }
.screen-reasons.ten .blob {
  left: 22%; }
  .screen-reasons.ten .blob.trigger i:nth-child(2) {
    top: 52%;
    left: -70%; }
  .screen-reasons.ten .blob.trigger i:nth-child(3) {
    top: -10%;
    left: -40%; }
  .screen-reasons.ten .blob.trigger i:nth-child(4) {
    top: 40%;
    left: 130%; }
@media (min-height: 425px) {
  .screen-reasons .positioner .downbutton {
    display: inline-block; } }
@media (min-height: 450px) {
  .screen-reasons .positioner h2 {
    font-size: 120%; }
  .screen-reasons .positioner p {
    font-size: 95%; } }
@media (min-width: 600px) and (min-height: 500px) {
  .screen-reasons .positioner h2 {
    font-size: 150%; }
    .screen-reasons .positioner h2 span {
      font-size: 75%;
      margin-bottom: .1em; } }
@media (min-width: 720px) and (min-height: 540px) {
  .screen-reasons .positioner {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    width: 82.5%;
    max-width: 900px; } }
  @media (min-width: 720px) and (min-height: 540px) and (min-width: 700px) {
    .screen-reasons .positioner {
      padding-left: 4rem;
      padding-right: 4rem; } }
  @media (min-width: 720px) and (min-height: 540px) and (min-width: 900px) {
    .screen-reasons .positioner {
      padding-left: 5.5rem;
      padding-right: 5.5rem; } }
  @media (min-width: 720px) and (min-height: 540px) and (min-width: 1100px) {
    .screen-reasons .positioner {
      padding-left: 7.5rem;
      padding-right: 7.5rem; } }
@media (min-width: 720px) and (min-height: 540px) {
    .screen-reasons .positioner h2 {
      font-size: 180%; }
      .screen-reasons .positioner h2 span {
        font-size: 60%;
        margin-bottom: .1em; }
    .screen-reasons .positioner p {
      font-size: 90%; } }
@media (min-width: 900px) and (min-height: 540px) {
  .screen-reasons .positioner .blob {
    top: -7.5em;
    width: 7em;
    height: 7em; } }
@media (min-width: 1000px) and (min-height: 540px) {
  .screen-reasons .positioner h2 {
    font-size: 220%; }
    .screen-reasons .positioner h2 span {
      font-size: 60%;
      margin-bottom: .15em; }
  .screen-reasons .positioner p {
    font-size: 110%; } }
@media (min-width: 1000px) and (min-height: 700px) {
  .screen-reasons .positioner .blob {
    top: -8.5em;
    width: 7.5em;
    height: 7.5em; } }
@media (min-width: 1200px) and (min-height: 700px) {
  .screen-reasons .positioner .blob {
    top: -9.25em;
    width: 8em;
    height: 8em; } }

.screen-how {
  background-color: #4A94D0;
  padding: 0; }
  .screen-how .flex {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    color: #ADCDE8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100%;
    min-height: calc(100vh - 3.5rem); }
    @media (min-height: 600px) {
      .screen-how .flex {
        padding-top: 2rem;
        padding-bottom: 2rem; } }
    @media (min-width: 700px) {
      .screen-how .flex {
        padding-left: 2rem;
        padding-right: 2rem; } }
    @media (min-width: 900px) {
      .screen-how .flex {
        padding-left: 2.5rem;
        padding-right: 2.5rem; } }
    @media (min-width: 650px) and (min-height: 500px) {
      .screen-how .flex {
        min-height: 100vh; } }
  .screen-how .slide:nth-child(2) p,
  .screen-how .slide:nth-child(2) li,
  .screen-how .slide:nth-child(3) p,
  .screen-how .slide:nth-child(3) li,
  .screen-how .slide:nth-child(4) p,
  .screen-how .slide:nth-child(4) li {
    color: #fff; }
  .screen-how .inner {
    width: 100%;
    padding: 2rem 3.4rem 5rem 0; }
  .screen-how .slide:nth-child(1) .inner {
    padding: 2rem 2rem 5rem 2rem; }
  .screen-how h2, .screen-how h3, .screen-how a, .screen-how strong {
    color: #fff; }
  .screen-how a {
    text-decoration: none; }
    .screen-how a:hover, .screen-how a:focus {
      text-decoration: underline; }
  .screen-how .top-img {
    display: none;
    float: left;
    width: 33%;
    height: auto;
    margin: 7% 0 0 5%; }
  .screen-how .bottom-images {
    margin-top: 1.4rem; }
    .screen-how .bottom-images img {
      display: block;
      width: 42%;
      height: auto;
      margin: 0 auto 7% auto; }
      .screen-how .bottom-images img:nth-child(1) {
        width: 45%; }
  .screen-how h2 {
    font-size: 140%; }
  .screen-how h3 {
    font-size: 130%; }
  .screen-how .left-arrow,
  .screen-how .right-arrow {
    position: absolute;
    top: 50%;
    width: 2rem;
    height: 2rem;
    transform: translateY(-50%);
    border: solid 1.5px #316DA2;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 30% auto;
    transition: background-color 150ms ease-out, border-color 150ms ease-out; }
    .screen-how .left-arrow:hover,
    .screen-how .right-arrow:hover {
      background-color: #fff;
      border-color: #fff; }
  .screen-how .left-arrow {
    background-position-x: 47%;
    left: 1.5rem;
    display: none; }
  .screen-how .right-arrow {
    background-position-x: 53%;
    right: 1.5rem; }
  .screen-how .downbutton {
    position: absolute;
    left: 50%;
    bottom: 1.5rem;
    transform: translateX(-50%); }
    @media (min-height: 600px) {
      .screen-how .downbutton {
        bottom: 2rem; } }
  .screen-how .controls {
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 7rem; }
    .screen-how .controls .left-arrow,
    .screen-how .controls .right-arrow {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      top: auto;
      left: auto;
      right: auto;
      transform: none;
      width: 36px;
      height: 36px; }
      .screen-how .controls .left-arrow:hover,
      .screen-how .controls .right-arrow:hover {
        background-color: #1f4567;
        border-color: #1f4567; }
    .screen-how .controls .dot {
      display: inline-block;
      vertical-align: middle;
      width: 14px;
      height: 14px;
      background-color: #316DA2;
      border-radius: 50%;
      transition: background-color 150ms ease-out; }
      .screen-how .controls .dot:hover {
        background-color: #1f4567; }
      .screen-how .controls .dot.active {
        background-color: #fff; }
    .screen-how .controls .left-arrow,
    .screen-how .controls .dot {
      margin-right: 10px; }
  .screen-how .slide:nth-child(1) {
    text-align: center; }
    .screen-how .slide:nth-child(1) p {
      max-width: 28em;
      margin-left: auto;
      margin-right: auto; }
  @media (min-width: 370px) {
    .screen-how .inner {
      padding: 2rem 3.4rem 5rem 3.4rem; }
    .screen-how .slide:nth-child(1) .inner {
      padding: 2rem 3.4rem 5rem 3.4rem; }
    .screen-how .left-arrow {
      display: block; }
    .screen-how .bottom-images {
      margin-top: 8%; }
      .screen-how .bottom-images img {
        float: left;
        width: 30%;
        margin: 0; }
        .screen-how .bottom-images img:nth-child(1) {
          width: 32%; }
        .screen-how .bottom-images img:nth-child(1), .screen-how .bottom-images img:nth-child(2) {
          margin-right: 4%; }
        .screen-how .bottom-images img:nth-child(2), .screen-how .bottom-images img:nth-child(3) {
          margin-top: 1.5%; } }
  @media (min-width: 400px) {
    .screen-how h2 {
      font-size: 180%; }
    .screen-how .slide:nth-child(1) p, .screen-how .slide:nth-child(1) ul {
      font-size: 120%; } }
  @media (min-width: 700px) {
    .screen-how h2 {
      font-size: 220%; }
    .screen-how h3 {
      font-size: 160%; }
    .screen-how p, .screen-how ul {
      font-size: 110%; }
    .screen-how .small-left-arrow,
    .screen-how .small-right-arrow {
      width: 2.5rem;
      height: 2.5rem; } }
  @media (min-width: 720px) {
    .screen-how .flex {
      padding-left: 2.5rem;
      padding-right: 2.5rem; } }
  @media (min-width: 720px) and (min-width: 700px) {
    .screen-how .flex {
      padding-left: 4rem;
      padding-right: 4rem; } }
  @media (min-width: 720px) and (min-width: 900px) {
    .screen-how .flex {
      padding-left: 5.5rem;
      padding-right: 5.5rem; } }
  @media (min-width: 720px) and (min-width: 1100px) {
    .screen-how .flex {
      padding-left: 7.5rem;
      padding-right: 7.5rem; } }

  @media (min-width: 900px) and (min-height: 600px) {
    .screen-how .small-left-arrow, .screen-how .small-right-arrow {
      display: none; }
    .screen-how .controls {
      display: block; }
    .screen-how .flex {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      min-height: calc(100vh - 6vh - 36px); } }
  @media (min-width: 900px) and (min-height: 600px) and (min-width: 700px) {
    .screen-how .flex {
      padding-left: 4rem;
      padding-right: 4rem; } }
  @media (min-width: 900px) and (min-height: 600px) and (min-width: 900px) {
    .screen-how .flex {
      padding-left: 5.5rem;
      padding-right: 5.5rem; } }
  @media (min-width: 900px) and (min-height: 600px) and (min-width: 1100px) {
    .screen-how .flex {
      padding-left: 7.5rem;
      padding-right: 7.5rem; } }
  @media (min-width: 900px) and (min-height: 600px) {
    .screen-how .inner {
      max-width: 1000px;
      padding: 2rem 3.4rem 9rem 3.4rem;
      margin: 0 auto; }
    .screen-how .slide:nth-child(1) .inner {
      padding: 2rem 3.4rem 9rem 3.4rem; }
    .screen-how .slide:nth-child(1) p {
      font-size: 140%; }
    .screen-how .top-img {
      display: block; }
    .screen-how .bottom-images {
      display: none; }
    .screen-how .right {
      float: right;
      width: 50%; } }
  @media (min-width: 1100px) and (min-height: 700px) {
    .screen-how p, .screen-how ul {
      font-size: 120%; }
    .screen-how .slide:nth-child(1) p {
      font-size: 180%; } }
  @media (min-width: 1700px) and (min-height: 1000px) {
    .screen-how h2 {
      font-size: 250%; } }

.screen-what {
  position: relative;
  padding-top: 0;
  padding-bottom: 0; }
  .screen-what .flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: calc(100vh - 3.5rem);
    margin: 0 auto;
    width: 100%;
    max-width: 1300px; }
    @media (min-width: 650px) and (min-height: 500px) {
      .screen-what .flex {
        min-height: 100vh; } }
  .screen-what .flexh {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%; }
  .screen-what .illustration {
    display: none;
    position: relative; }
    .screen-what .illustration:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 83.88889%; }
    .screen-what .illustration img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      opacity: 0;
      transition: opacity 330ms cubic-bezier(0.25, 0.46, 0.45, 0.94); }
      .screen-what .illustration img.active {
        opacity: 1; }
  .screen-what .concertina {
    padding: 3.5rem 0 8rem 0; }
  .screen-what h2 {
    font-size: 140%; }
  .screen-what h3 {
    font-size: 120%; }
  .screen-what .downbutton {
    position: absolute;
    left: 50%;
    bottom: 1.5rem;
    transform: translateX(-50%); }
    @media (min-height: 600px) {
      .screen-what .downbutton {
        bottom: 2rem; } }
  @media (min-width: 400px) {
    .screen-what h2 {
      font-size: 180%; }
    .screen-what h3 {
      font-size: 130%; } }
  @media (min-width: 700px) {
    .screen-what h2 {
      font-size: 220%; }
    .screen-what h3 {
      font-size: 140%; } }
  @media (min-width: 800px) {
    .screen-what .illustration {
      display: block;
      width: 33%; }
    .screen-what .concertina {
      width: 60%; } }
  @media (min-width: 720px) {
    .screen-what {
      padding-left: 0;
      padding-right: 0; }
      .screen-what .flex {
        padding-left: 2.5rem;
        padding-right: 2.5rem; } }
    @media (min-width: 720px) and (min-width: 700px) {
      .screen-what .flex {
        padding-left: 4rem;
        padding-right: 4rem; } }
    @media (min-width: 720px) and (min-width: 900px) {
      .screen-what .flex {
        padding-left: 5.5rem;
        padding-right: 5.5rem; } }
    @media (min-width: 720px) and (min-width: 1100px) {
      .screen-what .flex {
        padding-left: 7.5rem;
        padding-right: 7.5rem; } }

  @media (min-width: 900px) {
    .screen-what p {
      font-size: 110%; } }
  @media (min-width: 1700px) and (min-height: 1000px) {
    .screen-what .flex {
      max-width: 1400px; }
    .screen-what h2 {
      font-size: 250%; }
    .screen-what h3 {
      font-size: 150%; }
    .screen-what p {
      font-size: 120%; } }

.screen-about {
  background-color: #394449;
  color: #ccc;
  padding-top: 2rem;
  padding-bottom: 2rem; }
  .screen-about .button {
    position: absolute;
    left: 50%;
    bottom: 1.5rem;
    transform: translateX(-50%);
    white-space: nowrap;
    color: #fff; }
    @media (min-height: 600px) {
      .screen-about .button {
        bottom: 2rem; } }
    .screen-about .button:hover {
      text-decoration: none; }
    @media (min-width: 650px) and (min-height: 500px) {
      .screen-about .button {
        display: none; } }
  .screen-about .inner {
    margin: 0 auto 5rem auto;
    max-width: 1300px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    min-height: calc(100vh - 9rem); }
  .screen-about a {
    color: #4A94D0;
    text-decoration: none; }
    .screen-about a:hover, .screen-about a:focus {
      text-decoration: underline; }
  .screen-about h2 {
    font-size: 140%; }
  .screen-about .big {
    font-size: 120%;
    color: #fff;
    max-width: 19em; }
  .screen-about .contact {
    margin-top: 3em; }
    .screen-about .contact p {
      margin-bottom: 0; }
    .screen-about .contact span {
      color: #fff; }
  @media (min-width: 400px) {
    .screen-about h2 {
      font-size: 180%; }
    .screen-about .big {
      font-size: 140%; } }
  @media (min-width: 700px) {
    .screen-about h2 {
      font-size: 220%; }
    .screen-about .big {
      font-size: 150%; }
    .screen-about p {
      font-size: 110%; }
    .screen-about .contact p {
      display: inline-block; }
      .screen-about .contact p.uk {
        margin-right: 6em; } }
  @media (min-width: 720px) {
    .screen-about {
      padding-left: 0;
      padding-right: 0; }
      .screen-about .inner {
        padding-left: 2.5rem;
        padding-right: 2.5rem; } }
    @media (min-width: 720px) and (min-width: 700px) {
      .screen-about .inner {
        padding-left: 4rem;
        padding-right: 4rem; } }
    @media (min-width: 720px) and (min-width: 900px) {
      .screen-about .inner {
        padding-left: 5.5rem;
        padding-right: 5.5rem; } }
    @media (min-width: 720px) and (min-width: 1100px) {
      .screen-about .inner {
        padding-left: 7.5rem;
        padding-right: 7.5rem; } }

  @media (min-width: 1000px) {
    .screen-about .float .left {
      float: left;
      width: 55%; }
    .screen-about .float .right {
      float: right;
      width: 35%; }
      .screen-about .float .right p {
        position: relative;
        top: -.4em; } }
  @media (min-width: 1050px) and (min-height: 650px) {
    .screen-about .inner {
      margin: 0 auto;
      display: block;
      min-height: 0; }
    .screen-about .contact {
      position: absolute;
      left: 0;
      bottom: 1.5rem;
      width: 100%; } }
  @media (min-width: 1050px) and (min-height: 650px) and (min-height: 600px) {
    .screen-about .contact {
      bottom: 2rem; } }
  @media (min-width: 1050px) and (min-height: 650px) {
      .screen-about .contact .c-inner {
        margin: 0 auto;
        max-width: 1300px;
        padding-left: 2.5rem;
        padding-right: 2.5rem; } }
    @media (min-width: 1050px) and (min-height: 650px) and (min-width: 700px) {
      .screen-about .contact .c-inner {
        padding-left: 4rem;
        padding-right: 4rem; } }
    @media (min-width: 1050px) and (min-height: 650px) and (min-width: 900px) {
      .screen-about .contact .c-inner {
        padding-left: 5.5rem;
        padding-right: 5.5rem; } }
    @media (min-width: 1050px) and (min-height: 650px) and (min-width: 1100px) {
      .screen-about .contact .c-inner {
        padding-left: 7.5rem;
        padding-right: 7.5rem; } }
  @media (min-width: 1050px) and (min-height: 650px) {
    .screen-about .flex {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      min-height: calc(100vh - 9rem);
      width: 100%; } }
  @media (min-width: 1100px) and (min-height: 650px) {
    .screen-about .big {
      font-size: 170%; } }
  @media (min-width: 1200px) and (min-height: 800px) {
    .screen-about .float p {
      font-size: 130%; }
    .screen-about p.big {
      font-size: 180%; } }
  @media (min-width: 1700px) and (min-height: 1000px) {
    .screen-about .inner,
    .screen-about .contact .c-inner {
      max-width: 1400px; }
    .screen-about h2 {
      font-size: 250%; } }

.debug-vpwidth {
  position: fixed;
  z-index: 9999;
  opacity: .2;
  bottom: 0;
  right: 0;
  font-size: 10pt;
  background: #000;
  color: #fff;
  padding: .25em .35em;
  transition: opacity 100ms ease-in; }
  .debug-vpwidth:hover {
    opacity: .7; }

/*# sourceMappingURL=thecosystems.css.map */
