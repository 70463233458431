.screen-explained {
	
	background-color: $col-alternate-bg;
	position: relative;
	padding-top: 0;
	padding-bottom: 0;
	
	.flex {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		min-height: calc(100vh - #{$top-bar-height});
		@media (min-width: $sidebar-min-width) and (min-height: $sidebar-min-height) {
			min-height: 100vh;
		}
		margin: 0 auto;
		width: 100%;
		max-width: 1300px;
	}
	
	.concertina {
		padding: 3.5rem 0 8rem 0;
	}
	
	h2 {
		font-size: 140%;
	}
	
	h3 {
		font-size: 120%;
	}
	
	p {
		color: $col-white;
		&.big {
			font-size: 110%;
		}
	}
	
	.downbutton {
		position: absolute;
		left: 50%;
		@include std-bottom;
		transform: translateX(-50%);
	}
	
	@media (min-width: 400px) {
	
		h2 {
			font-size: 180%;
		}
		h3 {
			font-size: 130%;
		}
		
	}
	
	@media (min-width: 700px) {
		h2 {
			font-size: 220%;
		}
		h3 {
			font-size: 140%;
		}
		p.big {
			font-size: 130%;
		}
	}
	
	@media (min-width: 720px) {
		padding-left: 0;
		padding-right: 0;
		.flex {
			@include big-padding-horizontal;
		}
		.left {
			float: left;
			width: 47%;
		}
		.right {
			float: right;
			width: 47%;
		}
	}
	
	@media (min-width: 900px) {
		p {
			font-size: 110%;
		}
		.left,
		.right {
			width: 48%;
		}
	}
	
	@media (min-width: 1300px) {
		.left,
		.right {
			width: 48.5%;
		}
	}

	@media (min-width: 1700px) and (min-height: 1000px) {
		.flex {
			max-width: 1400px;
		}
		h2 {
			font-size: 250%;
		}
		h3 {
			font-size: 150%;
		}
		p {
			font-size: 120%;
		}
		p.big {
			font-size: 140%;
		}
	}
	
}
