.screen-how {

	background-color: $col-blue;
	padding: 0;
	
	.flex {
		@include std-padding-vertical;
		@include std-padding-horizontal;
		
		color: $col-light-blue;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		
		width: 100%;
		min-height: calc(100vh - #{$top-bar-height});
		@media (min-width: $sidebar-min-width) and (min-height: $sidebar-min-height) {
			min-height: 100vh;
		}
		
	}
	
	.slide:nth-child(2),
	.slide:nth-child(3),
	.slide:nth-child(4) {
		p,
		li {
			color: $col-white;
		}
	}
		
	
	
	.inner {
		width: 100%;
		padding: 2rem 3.4rem 5rem 0;
	}
	
	.slide:nth-child(1) .inner {
		padding: 2rem 2rem 5rem 2rem;
	}
	
	h2, h3, a, strong {
		color: $col-white;
	}
	
	a {
		text-decoration: none;
		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
	
	.top-img {
		display: none;
		float: left;
		width: 33%;
		height: auto;
		margin: 7% 0 0 5%;
	}
	
	.bottom-images {
		margin-top: 1.4rem;
		img {
			display: block;
			width: 42%;
			height: auto;
			margin: 0 auto 7% auto;
			&:nth-child(1) {
				width: 45%;
			}
		}
	}
	
	
	h2 {
		font-size: 140%;
	}
	
	h3 {
		font-size: 130%;
	}
	
	.left-arrow,
	.right-arrow {
		position: absolute;
		top: 50%;
		width: 2rem;
		height: 2rem;
		transform: translateY(-50%);
		border: solid 1.5px $col-dark-blue;
		border-radius: 50%;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: 30% auto;
		transition:
			background-color 150ms ease-out,
			border-color 150ms ease-out;
		&:hover {
			background-color: $col-white;
			border-color: $col-white;
		}
	}
	
	.left-arrow {
		background-position-x: 47%;
		left: 1.5rem;
		display: none;
		
	}
	
	.right-arrow {
		background-position-x: 53%;
		right: 1.5rem;
	}
	
	.downbutton {
		position: absolute;
		left: 50%;
		@include std-bottom;
		transform: translateX(-50%);
	}
	
	.controls {
		display: none;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 7rem;
		.left-arrow,
		.right-arrow {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			top: auto;
			left: auto;
			right: auto;
			transform: none;	
			width: 36px;
			height: 36px;
			&:hover {
				background-color: darken($col-dark-blue, 15%);
				border-color: darken($col-dark-blue, 15%);
			}		
		}
		.dot {
			display: inline-block;
			vertical-align: middle;
			width: 14px;
			height: 14px;
			background-color: $col-dark-blue;
			border-radius: 50%;
			transition: background-color 150ms ease-out;
			&:hover {
				background-color: darken($col-dark-blue, 15%);
			}
			&.active {
				background-color: $col-white;
			}
		}
		.left-arrow,
		.dot {
			margin-right: 10px;
		}
	}
	
	.slide:nth-child(1) {
		text-align: center;
		p {
			max-width: 28em;
			margin-left: auto;
			margin-right: auto;
		}
	}
	
	@media (min-width: 370px) {
		.inner {
			padding: 2rem 3.4rem 5rem 3.4rem;
		}
		.slide:nth-child(1) .inner {
			padding: 2rem 3.4rem 5rem 3.4rem;
		}
		.left-arrow {
			display: block;
		}
		.bottom-images {
			margin-top: 8%;
			img {
				float: left;
				width: 30%;
				margin: 0;
				&:nth-child(1) {
					width: 32%;
				}
				&:nth-child(1),
				&:nth-child(2) {
					margin-right: 4%;
				}
				&:nth-child(2),
				&:nth-child(3) {
					margin-top: 1.5%;
				}
			}
		}
	}
	
	@media (min-width: 400px) {
		h2 {
			font-size: 180%;
		}
		.slide:nth-child(1) {
			p, ul {
				font-size: 120%;
			}
		}
	}
	
	@media (min-width: 700px) {
		h2 {
			font-size: 220%;
		}
		h3 {
			font-size: 160%;
		}
		p, ul {
			font-size: 110%;
		}
		.small-left-arrow,
		.small-right-arrow {
			width: 2.5rem;
			height: 2.5rem;
		}
	}
	
	@media (min-width: 720px) {
		.flex {
			@include big-padding-horizontal;
		}
	}
	
	@media (min-width: 900px) and (min-height: 600px) {
		.small-left-arrow, .small-right-arrow { display: none; }
		.controls { display: block; }
		.flex {
			@include big-padding-horizontal;
			min-height: calc(100vh - 6vh - 36px);
		}
		.inner {
			max-width: 1000px;
			padding: 2rem 3.4rem 9rem 3.4rem;
			margin: 0 auto;
		}
		.slide:nth-child(1) .inner {
			padding: 2rem 3.4rem 9rem 3.4rem;
		}
		.slide:nth-child(1) {
			p {
				font-size: 140%;
			}
		}
		.top-img {
			display: block;
		}
		.bottom-images {
			display: none;
		}
		.right {
			float: right;
			width: 50%;
		}
	}
	
	@media (min-width: 900px) and (min-height: 800px) {
		.controls {
			//bottom: 6vh;
		}
	}
	
	@media (min-width: 1100px) and (min-height: 700px) {
		p, ul {
			font-size: 120%;
		}
		.slide:nth-child(1) {
			p {
				font-size: 180%;
			}
		}
	}
	
	@media (min-width: 1700px) and (min-height: 1000px) {
		h2 {
			font-size: 250%;
		}
	}

}