// colours
$col-white: #fff;
$col-blue: #4A94D0;
$col-light-blue: #ADCDE8;
$col-dark-blue: #316DA2;
$col-dark-grey: #6F6F6E;
$col-light-grey: #ccc;
$col-sidebar-bg: #2C353A;
$col-menu-bg: #1F272B;
$col-alternate-bg: #394449;

$col-error-red: #D14850;

$col-sect-teal: #48B9B7;
$col-sect-purple: #905BA1;
$col-sect-pink: #F19BC2;
$col-sect-gold: #F8AC13;
$col-sect-fuschia: #CC367B;
$col-sect-darkgreen: #5B9312;
$col-sect-orange: #EA5C1B;
$col-sect-lightgreen: #A1C52F;
$col-sect-lightblue: #438CCB;
$col-sect-darkblue: #316DA2;
$col-sect-yellow: #F8D029;

$col-node-1: $col-sect-orange;
$col-node-2: $col-sect-teal; 
$col-node-3: $col-sect-purple;
$col-node-4: $col-sect-fuschia;
$col-node-5: $col-sect-lightgreen;
$col-node-6: $col-sect-gold;
$col-node-7: $col-sect-darkgreen;
$col-node-8: $col-sect-pink;

// pertinent breakpoints
$min-page-width: 320px;
$sidebar-min-width: 650px;
$sidebar-min-height: 500px;
//$max-page-width: 1300px;

// easing defs
$ease-out-quad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$ease-in-quad: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$ease-out-quart: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.320, 1.275);
$ease-in-back: cubic-bezier(0.600, -0.280, 0.735, 0.045);
$ease-in-out-quad: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$ease-in-custom-menu: cubic-bezier(0.455, 0.030, 0.200, 0.975); 
$ease-out-custom-menu: cubic-bezier(0.825, 0.055, 0.570, 0.960);

// menu animation vars
$menu-animation-time: 650ms;
$menu-animation-ease-in: $ease-in-custom-menu;
$menu-animation-ease-out: $ease-in-out-cubic;
$top-bar-height: 3.5rem;
$side-bar-width: 5rem;
$top-menu-height: 25rem;
$side-menu-width: 24rem;


