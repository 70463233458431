@mixin image-contain {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

@mixin image-cover {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}




@mixin img-menu-icon {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzUgMjciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgaWQ9IlBhZ2UtMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBpZD0ibWVudS1pY29uIiBmaWxsPSIjNDM4Q0NCIj48cGF0aCBkPSJNMiA0aDMwLjk5OGMxLjEwNSAwIDItLjg5NSAyLTJzLS44OTUtMi0yLTJIMkMuODk1IDAgMCAuODk1IDAgMnMuODk1IDIgMiAyeiIgaWQ9IlN0cm9rZS0xIi8+PHBhdGggZD0iTTIgMTUuMTg1aDIzLjk5OGMxLjEwNSAwIDItLjg5NiAyLTIgMC0xLjEwNS0uODk1LTItMi0ySDJjLTEuMTA1IDAtMiAuODk1LTIgMiAwIDEuMTA0Ljg5NSAyIDIgMnoiIGlkPSJTdHJva2UtMyIvPjxwYXRoIGQ9Ik0yIDI2LjM3aDI4Ljk5OGMxLjEwNSAwIDItLjg5NiAyLTIgMC0xLjEwNS0uODk1LTItMi0ySDJjLTEuMTA1IDAtMiAuODk1LTIgMiAwIDEuMTA0Ljg5NSAyIDIgMnoiIGlkPSJTdHJva2UtNSIvPjwvZz48L2c+PC9zdmc+);
}
.img-menu-icon {
	@include img-menu-icon;
}

@mixin img-white-arrow-right {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTYgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgaWQ9IlBhZ2UtMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBpZD0id2hpdGUtYXJyb3ctcmlnaHQiIGZpbGw9IiNGRkYiPjxwYXRoIGQ9Ik00LjI0LjZsMTEuNjEzIDExLjYxNWMuMTk2LjE5Ni4xOTYuNTMyIDAgLjczTDQuMjQgMjQuNTU2Yy0uMTk3LjE5Ni0uNTMzLjE5Ni0uNzMgMGwtMy41MzQtMy41MzVjLS4xOTYtLjE5Ni0uMTk2LS41MzIgMC0uNzNMNy42OSAxMi41OC0uMDI0IDQuODY0Yy0uMTk2LS4xOTYtLjE5Ni0uNTMzIDAtLjczTDMuNTEuNjAyYy4xMTMtLjA4My4yMjMtLjE0LjM2My0uMTQuMTQyIDAgLjI1NC4wNTcuMzY2LjE0IiBpZD0iRmlsbC00MSIvPjwvZz48L2c+PC9zdmc+);
}

@mixin img-down-arrow-blue {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNjEgMzUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgaWQ9IlBhZ2UtMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBpZD0iZG93bi1hcnJvdy1ibHVlIiBmaWxsPSIjNDM4Q0NCIj48cGF0aCBkPSJNNi4wNSAxLjAzOGMtMS4zODMtMS4zODQtMy42MjctMS4zODQtNS4wMTIgMC0xLjM4NCAxLjM4NC0xLjM4NCAzLjYzIDAgNS4wMTNsMjkuMyAyOS4zMDMgMjkuMy0yOS4zMDJjMS4zODUtMS4zODMgMS4zODUtMy42MjggMC01LjAxMi0xLjM4My0xLjM4NC0zLjYyOC0xLjM4NC01LjAxMiAwbC0yNC4yOSAyNC4yOUw2LjA1MiAxLjAzN3oiIGlkPSJQYWdlLTEiLz48L2c+PC9nPjwvc3ZnPg==);
}

@mixin img-cross-black {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNDcgNDciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgaWQ9IlBhZ2UtMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBpZD0iY3Jvc3MtYmxhY2siIGZpbGw9IiMwMDAiPjxwYXRoIGlkPSJDb21iaW5lZC1TaGFwZSIgZD0iTTE5IDI0TDAgNDNsNCA0IDE5LTE5IDIwIDE5IDQtNC0xOS0xOUw0NyA0bC00LTQtMjAgMTlMNCAwIDAgNHoiLz48L2c+PC9nPjwvc3ZnPg==);
}

.img-right-arrow-dark-blue {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzUgNjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgaWQ9IlBhZ2UtMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBpZD0icmlnaHQtYXJyb3ctZGFyay1ibHVlIiBmaWxsPSIjMzE2REEyIj48cGF0aCBkPSJNMS4wMjggNTQuOTE3Yy0xLjM3IDEuMzktMS4zNyAzLjY0NyAwIDUuMDQgMS4zNyAxLjM5IDMuNTkyIDEuMzkgNC45NjMgMEwzNSAzMC41IDUuOTkgMS4wNDVDNC42Mi0uMzQ4IDIuNC0uMzQ4IDEuMDMgMS4wNDRjLTEuMzcgMS4zOS0xLjM3IDMuNjQ4IDAgNS4wNEwyNS4wNzQgMzAuNSAxLjAyOCA1NC45MTh6IiBpZD0iUGFnZS0xIi8+PC9nPjwvZz48L3N2Zz4=);
}

.img-left-arrow-dark-blue {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzUgNjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgaWQ9IlBhZ2UtMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBpZD0ibGVmdC1hcnJvdy1kYXJrLWJsdWUiIGZpbGw9IiMzMTZEQTIiPjxwYXRoIGQ9Ik0zMy45NzIgNTQuOTE3YzEuMzcgMS4zOSAxLjM3IDMuNjQ3IDAgNS4wNC0xLjM3IDEuMzktMy41OTIgMS4zOS00Ljk2MyAwTDAgMzAuNSAyOS4wMSAxLjA0NWMxLjM3LTEuMzkyIDMuNTkyLTEuMzkyIDQuOTYyIDAgMS4zNyAxLjM5IDEuMzcgMy42NDggMCA1LjA0TDkuOTI2IDMwLjVsMjQuMDQ2IDI0LjQxN3oiIGlkPSJQYWdlLTEiLz48L2c+PC9nPjwvc3ZnPg==);
}

@mixin img-plus-black {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzEgMzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxwYXRoIGQ9Ik0zMSAxNmMwIDguMDYtNi45NCAxNS0xNSAxNS05LjA2IDAtMTYtNi45NC0xNi0xNUMwIDYuOTQgNi45NCAwIDE2IDBjOC4wNiAwIDE1IDYuOTQgMTUgMTZ6IiBpZD0icGF0aC0xIi8+PG1hc2sgaWQ9Im1hc2stMiIgeD0iMCIgeT0iMCIgd2lkdGg9IjMxIiBoZWlnaHQ9IjMxIiBmaWxsPSIjZmZmIj48dXNlIHhsaW5rOmhyZWY9IiNwYXRoLTEiLz48L21hc2s+PC9kZWZzPjxnIGlkPSJQYWdlLTEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGcgaWQ9InBsdXMtYmxhY2siIHN0cm9rZT0iIzAwMCI+PHVzZSBpZD0iU3Ryb2tlLTEiIG1hc2s9InVybCgjbWFzay0yKSIgc3Ryb2tlLXdpZHRoPSIyIiB4bGluazpocmVmPSIjcGF0aC0xIi8+PHBhdGggZD0iTTkgMTUuNWgxMyIgaWQ9IlN0cm9rZS0zIi8+PHBhdGggZD0iTTE1LjUgMjJWOSIgaWQ9IlN0cm9rZS01Ii8+PC9nPjwvZz48L3N2Zz4=);
}

@mixin img-plus-white {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzEgMzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxwYXRoIGQ9Ik0zMSAxNmMwIDguMDYtNi45NCAxNS0xNSAxNS05LjA2IDAtMTYtNi45NC0xNi0xNUMwIDYuOTQgNi45NCAwIDE2IDBjOC4wNiAwIDE1IDYuOTQgMTUgMTZ6IiBpZD0icGF0aC0xIi8+PG1hc2sgaWQ9Im1hc2stMiIgeD0iMCIgeT0iMCIgd2lkdGg9IjMxIiBoZWlnaHQ9IjMxIiBmaWxsPSIjZmZmIj48dXNlIHhsaW5rOmhyZWY9IiNwYXRoLTEiLz48L21hc2s+PC9kZWZzPjxnIGlkPSJQYWdlLTEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGcgaWQ9InBsdXMtd2hpdGUiIHN0cm9rZT0iI0ZGRiI+PHVzZSBpZD0iU3Ryb2tlLTEiIG1hc2s9InVybCgjbWFzay0yKSIgc3Ryb2tlLXdpZHRoPSIyIiB4bGluazpocmVmPSIjcGF0aC0xIi8+PHBhdGggZD0iTTkgMTUuNWgxMyIgaWQ9IlN0cm9rZS0zIi8+PHBhdGggZD0iTTE1LjUgMjJWOSIgaWQ9IlN0cm9rZS01Ii8+PC9nPjwvZz48L3N2Zz4=);
}

@mixin img-loader {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBpZD0iUGFnZS0xIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxnIGlkPSJsb2FkZXIiIGZpbGw9IiM0MzhDQ0IiPjxwYXRoIGQ9Ik0xMDAgNTBjMCAyNy42MTQtMjIuMzg2IDUwLTUwIDUwUzAgNzcuNjE0IDAgNTAgMjIuMzg2IDAgNTAgMHY4QzI2LjgwNCA4IDggMjYuODA0IDggNTBzMTguODA0IDQyIDQyIDQyIDQyLTE4LjgwNCA0Mi00Mmg4eiIgaWQ9IkNvbWJpbmVkLVNoYXBlIi8+PC9nPjwvZz48L3N2Zz4=);
}