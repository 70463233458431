html {
	min-height: 100%;
	font-size: 10pt;
}

body {
	@include font-regular;
	color: $col-dark-grey;
	min-width: $min-page-width;
	min-height: 100%;
	line-height: 1.5;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	//-webkit-font-smoothing: antialiased;
	//-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

// fix overflow issue with menu open on iOS
.overflow-fix {
	overflow-x: hidden;
}



#particles {
	position: fixed;
	z-index: 0;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	
	// in sidebar mode (i.e. when parallax effects are switched on)
	// make element taller 
	@media (min-width: $sidebar-min-width) and (min-height: $sidebar-min-height) {
		height: 180vh;
	}
	
}

.screen {
	
	position: relative;
	//background-color: $col-white;
	@include std-padding-vertical;
	@include std-padding-horizontal;
	
	min-height: calc(100vh - #{$top-bar-height});
	@media (min-width: $sidebar-min-width) and (min-height: $sidebar-min-height) {
		min-height: 100vh;
	}
		
}


.sticky-container {
	
	.sticky-item {
		
		user-select: none;
		
		.fluid {
			opacity: 1;
		}
		
		
		.sticky {
			opacity: 0;
			display: none;
			
			position: fixed;
			margin-top: $top-bar-height;
			@media (min-width: $sidebar-min-width) and (min-height: $sidebar-min-height) {
				margin-top: 0;
				margin-left: $side-bar-width;	
			}
			
			// keep invisible sticky items in layout when menu is at top and menu open
			// to stop them animating into view on scroll. disable this when menu closed
			// or when in sidebar mode as an unwanted side effect is that they are still
			// clickable etc
			/*
			.menu-open & { display: block; }
			@media (min-width: $sidebar-min-width) and (min-height: $sidebar-min-height) {
				.menu-open & { display: none; }
			}
			*/
			
			
		}
	}
	
	
	&.stuck {
		
		.fluid {
			opacity: 0;
			display: none;
		}
		
		.sticky {
			opacity: 1;
			display: block;
			//.menu-open & { display: block; }
		}
		
	}
	
}





.demo-button-wrapper {
	display: none;
	position: fixed;
	z-index: 9;
	@include std-right;
	@include std-bottom;
	@media (min-width: $sidebar-min-width) and (min-height: $sidebar-min-height) { display: block; }
}

.line {
	position: absolute;
	z-index: 1;
	height: 1px;
	background-color: rgba(0,0,0,.15);
	transform-origin: 0 50%;
}




.swipe {
  overflow: hidden;
  visibility: hidden;
  position: relative;
}
.swipe-wrap {
  overflow: hidden;
  position: relative;
}
.swipe-wrap > div {
  float:left;
  width:100%;
  position: relative;
}

