.screen-about {
	
	background-color: $col-alternate-bg;
	color: $col-light-grey;
	padding-top: 2rem;
	padding-bottom: 2rem;
	
	.button {
		position: absolute;
		left: 50%;
		@include std-bottom;
		transform: translateX(-50%);
		white-space: nowrap;
		color: $col-white;
		&:hover {
			text-decoration: none;
		}
		@media (min-width: $sidebar-min-width) and (min-height: $sidebar-min-height) { display: none; }
	}
	
	
	.inner {
		margin: 0 auto 5rem auto;
		max-width: 1300px;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: center;
		min-height: calc(100vh - 9rem);
	}
	
	a {
		color: $col-blue;
		text-decoration: none;
		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
	
	h2 {
		font-size: 140%;
	}
	
	
	
	.big {
		font-size: 120%;
		color: $col-white;	
		max-width: 19em;
	}
	
	.contact {
		margin-top: 3em;
		p {
			margin-bottom: 0;
		}
		span {
			color: $col-white;
		}
	}
	
	
	@media (min-width: 400px) {
		h2 {
			font-size: 180%;
		}
		.big {
			font-size: 140%;
		}
	}
	
	@media (min-width: 700px) {
		h2 {
			font-size: 220%;
		}
		.big {
			font-size: 150%;
		}
		p {
			font-size: 110%;
		}
		.contact p {
			display: inline-block;
			&.uk {
				margin-right: 6em;
			}
		}
	}
	
	@media (min-width: 720px) {
		padding-left: 0;
		padding-right: 0;
		.inner {
			@include big-padding-horizontal;
		}
	}
	
	@media (min-width: 1000px) {
		.float {
			.left {
				float: left;
				width: 55%;
			}
			.right {
				float: right;
				width: 35%;
				p {
					position: relative;
					top: -.4em;
				}
			}
		}
	}
	
	@media (min-width: 1050px) and (min-height: 650px) {
		.inner {
			margin: 0 auto;
			display: block;
			min-height: 0;
		}
		.contact {
			position: absolute;
			left: 0;
			@include std-bottom;
			width: 100%;
			.c-inner {
				margin: 0 auto;
				max-width: 1300px;
				@include big-padding-horizontal;
			}
		}
		.flex {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			justify-content: center;
			min-height: calc(100vh - 9rem);
			width: 100%;
		}
	}
	
	@media (min-width: 1100px) and (min-height: 650px) {
	
		.big {
			font-size: 170%;
		}	
	}
	
	
	@media (min-width: 1200px) and (min-height: 800px) {
		.float p {
			font-size: 130%;
		}
		p.big {
			font-size: 180%;
		}	
	}
	
	
	
	@media (min-width: 1700px) and (min-height: 1000px) {
		.inner,
		.contact .c-inner {
			max-width: 1400px;
		}
		h2 {
			font-size: 250%;
		}
	}
	
}