//@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');

@mixin font-regular {
	font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
}

/*
@mixin font-semibold {
	font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 600;
}
*/

@mixin font-bold {
	font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 700;
}

