.button {
	display: inline-block;
	@include font-bold;
	background-color: $col-dark-blue;
	color: $col-white;
	text-decoration: none;
	padding: 1em 1.5em;
	border-radius: .3em;
	transition: background-color 200ms $ease-out-quad;
	cursor: pointer;
	box-shadow: 0 .2rem 1rem rgba(0,0,0,.15);
	&::after {
		display: inline-block;
		vertical-align: baseline;
		content: '';
		@include image-contain;
		@include img-white-arrow-right;
		width: .7em;
		height: .85em;
		margin-left: .5em;
	}
	&:hover,
	&:focus {
		background-color: $col-blue;
	}
}

.downbutton {
	display: inline-block;
	width: 2.75rem;
	height: 2.75rem;
	background-color: $col-white;
	box-shadow: 0 0 1.5rem rgba(0,0,0,.25);
	border-radius: 50%;
	@include img-down-arrow-blue;
	background-position: 50% 55%;
	background-repeat: no-repeat;
	background-size: 50% auto;
	transition: box-shadow 200ms $ease-out-quad;
	&:hover,
	&:focus {
		box-shadow: 0 0 .2rem rgba(0,0,0,.3);
	}
	
	@media (min-width: 700px) { 
		width: 3rem;
		height: 3rem;
	}
	
	@media (min-width: 1200px) { 
		width: 3.5rem;
		height: 3.5rem;
	}
	
}

h1, h2, h3 {
	@include font-bold;
	color: $col-blue;
	margin: .6em 0;
}

h1 {
	font-size: 140%;
	@media (min-width: 400px) { font-size: 180%; }
	@media (min-width: 700px) { font-size: 220%; }
	@media (min-width: 900px) { font-size: 280%; }
}

p {
	margin: 1em 0;
	line-height: 1.8;
}

ul.dot {
	list-style-type: disc;
	line-height: 1.8;
	margin-left: 2em;
	li {
		margin: .3em 0;
	}
}

strong {
	@include font-bold;
}

p.bluebold {
	@include font-bold;
	color: $col-blue;
}

hr {
	margin: 1.8em auto;
	width: 4em;
	outline: none;
	border: none;
	height: 2px;
	background-color: $col-blue;
}

input[type=text],
input[type=tel],
input[type=email] {
	display: block;
	outline: none;
	appearance: none;
	font-family: inherit;
	font-size: 90%;
	color: $col-dark-grey;
	margin: 1.25em 0;
	width: 100%;
	border: solid 1px $col-light-grey;
	border-radius: .25rem;
	padding: .85em 1.4em;
	transition:
		border-color 150ms ease-out,
		color 150ms ease-out;
	&::-webkit-input-placeholder {
		color: $col-light-grey;
	}
	&:-moz-placeholder {
		color: $col-light-grey;  
	}
	&::-moz-placeholder {
		color: $col-light-grey;
	}
	&:-ms-input-placeholder {
		color: $col-light-grey;
	}
	&:hover,
	&:focus {
		border-color: darken($col-light-grey, 12%);
	}
	&.error {
		color: $col-error-red;
		border-color: $col-error-red;
		box-shadow: 0 0 2px lighten($col-error-red, 20%);
	}
}

.replacecheckbox {
	display: block;
	position: relative;
	font-size: 90%;
	input[type=checkbox] {
		display: none;
	}
	i {
		display: inline-block;
		width: 2em;
		height: 2em;
		vertical-align: -.67em;
		border: solid 1px $col-light-grey;
		border-radius: .25rem;	
		transition: border-color 150ms ease-out;
		margin-right: 1em;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: 80% auto;
	}
	input[type=checkbox]:checked ~ i {
		background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNzEgNTEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgaWQ9IlBhZ2UtMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBpZD0idGljay1ibHVlIiBmaWxsPSIjNDM4Q0NCIj48cGF0aCBpZD0iQ29tYmluZWQtU2hhcGUiIGQ9Ik0yOC4yODQgMzMuNzRsLTE5LjgtMTkuOEwwIDIyLjQyNyAyOC4yODQgNTAuNzEgNzAuNzEgOC4yODUgNjIuMjI2LS4yeiIvPjwvZz48L2c+PC9zdmc+);
	}
	&:hover {
		i {
			border-color: darken($col-light-grey, 12%);
		}
	}
}





.concertina {
	
	width: 100%;
	
	.section {
		
		@include cf;
		border-bottom: solid 1px $col-dark-grey;
		padding: 1rem 0;
		
		h2, h3 {
			margin: 0;
		}
		
		.toggle {
			float: right;
			display: block;
			width: 2rem;
			height: 2rem;
			margin: 0 0 0 1rem;
			@include image-contain;
			@include img-plus-white;
			opacity: .33;
			cursor: pointer;
			transition: opacity 150ms ease-out;
			&:hover {
				opacity: 1;
			}
		}
			
		.inner {
			display: none;
			padding: 1.5rem 0;
			clear: both;
		}
		
		&.open {
		
			.toggle {
				opacity: 0;
				cursor: default;
			}

		}
		
		@media (min-width: 900px) {
			padding: 1.5rem 0;
		}
		
	}
	
	&.onwhite {
		.section {
			border-bottom-color: $col-light-grey;
			.toggle {
				@include img-plus-black;
				opacity: .3;
				&:hover { opacity: .8; }
			}
			&.open .toggle {
				opacity: 0;
			}
		}
	}
	
	
}





.flex-fix {
	display: flex;
	flex-direction: row;
}