.modal {
	
	display: none;
	position: fixed;
	z-index: 50;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	
	
	
	.panel {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $col-white;
		padding: 1.5rem;	
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		overflow-scrolling: touch;
	}
	
	.close {
		position: absolute;
		top: 1.5rem;
		right: 1.5rem;
		width: 1rem;
		height: 1rem;
		@include image-contain;
		@include img-cross-black;
		opacity: .2;
		transition: opacity 150ms ease-out;
		&:hover {
			opacity: .5;
		}
	}
	
	.logo {

		display: block;
		width: 1.1em;
		height: auto;
		margin: 0 auto 1.67rem auto;
	}
	
	.loader {
		position: absolute;
		z-index: 0;
		top: 50%;
		left: 50%;
		width: 2.2rem;
		height: 2.2rem;
		@include image-contain;
		@include img-loader;
		animation: loader 1s linear infinite;
		opacity: 0;
	}
	
	p {
		text-align: center;
		max-width: 26em;
		margin-left: auto;
		margin-right: auto;
	}
	
	form {
		margin-top: 1.33rem;
		position: relative;
		z-index: 1;
	}
	
	.replacecheckbox {
		margin-top: 1.2rem;
	}
	
	.submit {
		text-align: center;
		display: block;
		width: 100%;
		margin: .4em 0;
	}
	
	.thanks {
		position: absolute;
		z-index: 0;
		top: 2.5rem;
		left: 0;
		padding: 1.5rem;
		width: 100%;
		text-align: center;
		opacity: 0;
		h3 {
			font-size: 150%;
		}
		.button {
			width: 100%;
			margin-top: 1rem;
		}
	}
	
	.loader,
	.intro,
	.thanks,
	form {
		transition: opacity 330ms ease-out;
	}
	
	.loading {
		.loader {
			opacity: 1;
		}
		.intro,
		form {
			opacity: 0;
		}
	}
	
	.success {
		.thanks {
			opacity: 1;
			z-index: 2;
		}
		.intro,
		form {
			opacity: 0;
		}
	}
	
	
	

	&.appear {
		display: block;
		animation: fade-in 300ms $ease-out-quad both;
	}
	
	&.hide {
		display: block;
		animation: fade-out 300ms $ease-out-quad both;
	}
	
	
	
	
	@media (min-width: 400px) and (min-height: 500px) {
		
		
		background: rgba(0,0,0,.85);
		
		.panel {
			top: 50%;
			left: 50%;
			width: 90%;
			height: auto;
			max-width: 24em;
			transform: translateX(-50%) translateY(-50%);
			border-radius: .25rem;
			box-shadow: 0 0 1em rgba(0,0,0,.66);
			padding: 1.5rem 2rem;
			overflow: auto;
		}
		
		.thanks {
			padding: 1.5rem 2rem;
		}
		
		
		&.appear .panel {
			animation: modal-panel-in 300ms $ease-out-quad both;
		}
		
		&.hide .panel {
			animation: modal-panel-out 300ms $ease-out-quad both;
		}
		
		
	}

}


