@mixin aspect-ratio($width, $height) {
	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}
}

@mixin remove-aspect-ratio {
	&:before {
		display: none;
	}
}


// apply a natural box layout model to all elements, but allow components to change
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}


// clearfix hack
@mixin cf {
	zoom: 1;
	&::before,
	&::after {
		content: ' ';
		display: table;
	}
	&::after {
		clear: both;
	}
}
.cf {
	@include cf;
}




@mixin std-padding-horizontal {
	
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	
	@media (min-width: 700px) {
		padding-left: 2rem;
		padding-right: 2rem;
	}
	
	@media (min-width: 900px) {
		padding-left: 2.5rem;
		padding-right: 2.5rem;
	}
	
}

@mixin std-padding-vertical {
	
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	
	@media (min-height: 600px) {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
	
}

@mixin big-padding-horizontal {
	
	padding-left: 2.5rem;
	padding-right: 2.5rem;
	
	@media (min-width: 700px) {
		padding-left: 4rem;
		padding-right: 4rem;
	}
	
	@media (min-width: 900px) {
		padding-left: 5.5rem;
		padding-right: 5.5rem;
	}
	
	@media (min-width: 1100px) {
		padding-left: 7.5rem;
		padding-right: 7.5rem;
	}
	
}

@mixin std-top {
	
	top: 1.5rem;
	
	@media (min-height: 600px) {
		top: 2rem;
	}
	
}

@mixin std-bottom {
	
	bottom: 1.5rem;
	
	@media (min-height: 600px) {
		bottom: 2rem;
	}
	
}

@mixin std-left {
	
	left: 1.5rem;
	
	@media (min-width: 700px) {
		left: 2rem;
	}
	
	@media (min-width: 900px) {
		left: 2.5rem;
	}
	
}

@mixin std-right {
	
	right: 1.5rem;
	
	@media (min-width: 700px) {
		right: 2rem;
	}
	
	@media (min-width: 900px) {
		right: 2.5rem;
	}
	
}

@mixin big-left {
	
	left: 2.5rem;
	
	@media (min-width: 700px) {
		left: 4rem;
	}
	
	@media (min-width: 900px) {
		left: 5.5rem;
	}
	
	@media (min-width: 1100px) {
		left: 7.5rem;
	}
	
}

@mixin big-right {
	
	right: 2.5rem;
	
	@media (min-width: 700px) {
		right: 4rem;
	}
	
	@media (min-width: 900px) {
		right: 5.5rem;
	}
	
	@media (min-width: 1100px) {
		right: 7.5rem;
	}
	
}