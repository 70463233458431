$numbers: (1 "one") (2 "two") (3 "three") (4 "four") (5 "five") (6 "six") (7 "seven") (8 "eight");
$cols: $col-node-1 $col-node-2 $col-node-3 $col-node-4 $col-node-5 $col-node-6 $col-node-7 $col-node-8;

.reasons-sticky-container {
	
	position: relative;
	
	.sticky-progress {
			
		display: none;
		@media (min-width: 720px) and (min-height: 540px) { display: block; }
		
		.fluid,
		.sticky {
			@include big-left;
			top: 0;
			bottom: 0;		
		}
		
		.fluid {
			position: absolute;
		}
		
		.sticky {
			z-index: 8;
		}
		
		.wrapper {
			position: absolute;
			top: 50vh;
			left: 0;
			transform: translateY(-50%);
			
		}
		
		a {
			display: block;
			width: .75em;
			height: .75em;
			margin-bottom: 1em;
			&:last-child { margin-bottom: 0; }
			border-radius: 50%;
			background-color: $col-light-blue;
			transition: background-color 200ms $ease-out-quad;
			&.active {
				background-color: $col-blue;
			}
			@for $i from 1 through 8 {
				&:nth-child(#{$i}):hover {
					background-color: #{nth($cols,$i)};
				}
			}
		}
		
	}
	
	&.bottom .sticky-progress .wrapper {
		top: auto;
		bottom: 50vh;
		transform: translateY(50%);
	}
	
}


.screen-reasons {
	
	.line-start,
	.line-end {
		position: absolute;
		width: 1px;
		height: 1px;
	}
	.line-start {
		top: 0;
		left: 35%;
	}
	.line-end {
		bottom: 0;
		left: 70%;
	}
	
	
	.introtitle {
		position: absolute;
		z-index: 2;
		font-size: 90%;
		top: 50%;
		left: 50%;
		width: 14em;
		height: 14em;
		padding: 4.75em 1.5em 0 1.5em;
		transform: translateX(-50%) translateY(-50%);
		background-color: $col-blue;
		border-radius: 50%;
		text-align: center;
		box-shadow: 0 1em 2.5em rgba(0,0,0,.15);
		h2 {
			font-size: inherit;
			color: $col-white;
		}
		.downbutton {
			font-size: inherit;
			width: 2.2em;
			height: 2.2em;
			margin-top: .5em;
			box-shadow: none;
		}
		
		@media (min-height: 375px) {
			font-size: 100%;
		}
		
		@media (min-width: 600px) and (min-height: 500px) {
			font-size: 125%;	
		}
		
		@media (min-width: 800px) and (min-height: 600px) {
			font-size: 180%;
		}
		
		@media (min-width: 1200px) and (min-height: 900px) {
			font-size: 220%;
		}
		
		@media (min-width: 1700px) and (min-height: 1000px) {
			font-size: 260%;
		}
		
	}
	
	
		
	.positioner {
		
		position: absolute;
		z-index: 2;
		top: 50%;
		left: 50%;
		width: 100%;
		@include std-padding-horizontal;
		transform: translateY(-50%) translateX(-50%);
		
		.blob {
			position: absolute;
			top: -6em;
			bottom: 100%;
			width: 5.5em;
			height: 5.5em;
			i {
				position: absolute;
				z-index: 1;
				border-radius: 50%;
				&:nth-child(1) {
					z-index: 2;
					width: 100%;
					height: 100%;
					box-shadow: 0 .33em .75em rgba(0,0,0,.15);
					background-position: 50% 50%;
					background-size: 90% auto;
					background-repeat: no-repeat;
				}
				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4) {	
					box-shadow: 0 .15em .4em rgba(0,0,0,.25);
					top: 50%;
					left: 50%;
					transition: all 500ms $ease-out-quart;
					transform: translateX(-50%) translateY(-50%) scale(0);
				}
				&:nth-child(2) {
					width: .8em;
					height: .8em;
				}
				&:nth-child(3) {
					width: .6em;
					height: .6em;
				}
				&:nth-child(4) {
					width: .6em;
					height: .6em;
				}	
			}
			
			&.trigger {
				i {
					&:nth-child(2),
					&:nth-child(3),
					&:nth-child(4) {
						transform: translateX(-50%) translateY(-50%) scale(1);
					}
				}
			}
			
		
			
		}
		
		h2 {
			font-size: 110%;
			margin-bottom: .67em;
			&::after {
				content: '';
				display: block;
				height: 2px;
				width: 2em;
				background-color: $col-blue;
				margin-top: .67em;
			}
			span {
				display: block;
				font-size: 90%;
				margin-bottom: .2em;
			}
		}
		
		p {
			font-size: 80%;
			span {
				display: block;
				font-size: 120%;
				@include font-bold;
			}
		}
		
		.downbutton {
			display: none;
			margin-top: .8em;
		}
		
	}
	
	
	
	@each $i in $numbers {
		$n: nth($i,1);
		&.#{nth($i,2)} {
			
			h2 span { color: #{nth($cols,$n)}; }
			h2::after { background-color: #{nth($cols,$n)}; }
			.blob i {
				background-color: #{nth($cols,$n)};
				&:nth-child(1) {
					background-image: url('../img/node-#{$n}.svg');
				}
				&:nth-child(2) {
					transition-delay: random(900) + ms;
					transition-duration: (300 + random(1400)) + ms;
				}
				&:nth-child(3) {
					transition-delay: random(900) + ms;
					transition-duration: (300 + random(1400)) + ms;
				}
				&:nth-child(4) {
					transition-delay: random(900) + ms;
					transition-duration: (300 + random(1400)) + ms;
				}
			}
		}
	}
	
	
	&.one .blob {
		right: 7%;
		&.trigger {
			i:nth-child(2) {
				top: 20%;
				left: 140%;
			}
			i:nth-child(3) {
				top: 80%;
				left: 115%;
			}
			i:nth-child(4) {
				top: 10%;
				left: -40%;
			}
		}
	}
	
	&.two .blob {
		left: 20%;
		&.trigger {
			i:nth-child(2) {
				top: 100%;
				left: 109%;
			}
			i:nth-child(3) {
				top: 130%;
				left: 42%;
			}
			i:nth-child(4) {
				top: -10%;
				left: -50%;
			}
		}
	}
	
	&.three .blob {
		right: 25%;
		&.trigger {
			i:nth-child(2) {
				top: -5%;
				left: 115%;
			}
			i:nth-child(3) {
				top: 76%;
				left: 120%;
			}
			i:nth-child(4) {
				top: 100%;
				left: -55%;
			}
		}
	}
	
	&.four .blob {
		left: 10%;
		&.trigger {
			i:nth-child(2) {
				top: -5%;
				left: 160%;
			}
			i:nth-child(3) {
				top: 0%;
				left: -40%;
			}
			i:nth-child(4) {
				top: 60%;
				left: 130%;
			}
		}
	}
	
	&.five .blob {
		right: 8%;
		&.trigger {
			i:nth-child(2) {
				top: 80%;
				left: 180%;
			}
			i:nth-child(3) {
				top: 140%;
				left: 90%;
			}
			i:nth-child(4) {
				top: 5%;
				left: -34%;
			}
		}
	}

	&.six .blob {
		left: 15%;
		&.trigger {
			i:nth-child(3) {
				top: -11%;
				left: -66%;
			}
			i:nth-child(3) {
				top: -60%;
				left: -31%;
			}
			i:nth-child(4) {
				top: 55%;
				left: 122%;
			}
		}
	}
	
	&.seven .blob {
		right: 18%;
		&.trigger {
			i:nth-child(2) {
				top: -11%;
				left: 138%;
			}
			i:nth-child(3) {
				top: 50%;
				left: 150%;
			}
			i:nth-child(4) {
				top: 87%;
				left: -27%;
			}
		}
	}
	
	&.eight .blob {
		left: 30%;
		&.trigger {
			i:nth-child(2) {
				top: 5%;
				left: -40%;
			}
			i:nth-child(3) {
				top: -32%;
				left: 20%;
			}
			i:nth-child(4) {
				top: 120%;
				left: 120%;
			}
		}
	}
	
	&.nine .blob {
		right: 15%;
		&.trigger {
			i:nth-child(2) {
				top: 52%;
				left: -45%;
			}
			i:nth-child(3) {
				top: 3%;
				left: -19%;
			}
			i:nth-child(4) {
				top: 49%;
				left: 140%;
			}
		}
	}
	
	&.ten .blob {
		left: 22%;
		&.trigger {
			i:nth-child(2) {
				top: 52%;
				left: -70%;
			}
			i:nth-child(3) {
				top: -10%;
				left: -40%;
			}
			i:nth-child(4) {
				top: 40%;
				left: 130%;
			}
		}
	}
	
	
	
	
	@media (min-height: 425px) {
		
		.positioner .downbutton { display: inline-block; }
		
	}
	
	@media (min-height: 450px) {
		
		.positioner {
			h2 {
				font-size: 120%;
			}
			p {
				font-size: 95%;
			}
		}
		
	}
	
	@media (min-width: 600px) and (min-height: 500px) {
		
		.positioner h2 {
			font-size: 150%;
			span {
				font-size: 75%;
				margin-bottom: .1em;
			}
		}
		
	}
	
	@media (min-width: 720px) and (min-height: 540px) {
		
		.positioner {
			@include big-padding-horizontal;
			width: 82.5%;
			max-width: 900px;
			
			h2 {
				font-size: 180%;
				span {
					font-size: 60%;
					margin-bottom: .1em;
				}
			}
			p {
				font-size: 90%;
			}
		}
		
	}
	
	@media (min-width: 900px) and (min-height: 540px) {
		
		.positioner {

			.blob {
				top: -7.5em;
				width: 7em;
				height: 7em;
			}
			
		}
		
	}
	
	@media (min-width: 1000px) and (min-height: 540px) {
		
		.positioner {

			h2 {
				font-size: 220%;
				span {
					font-size: 60%;
					margin-bottom: .15em;
				}
			}
			p {
				font-size: 110%;
			}
		}
		
	}
	
	
	@media (min-width: 1000px) and (min-height: 700px) {
		
		.positioner {

			.blob {
				top: -8.5em;
				width: 7.5em;
				height: 7.5em;
			}
			
		}
		
	}
	
	@media (min-width: 1200px) and (min-height: 700px) {
		
		.positioner {

			.blob {
				top: -9.25em;
				width: 8em;
				height: 8em;
			}
			
		}
		
	}

	
	
}